// @ts-nocheck
import React from 'react';
import DataPreProcessing from '../DataPreProcessing/DataPreProcessing';
import ReportingTable from '../ReportingTable/ReportingTable';
import AdditionalReportingTable from '../AdditionalReportingTable/AdditionalReportingTable';

import './AddReporting.css';

const AddReporting = () => {
  return (
    <>
      <DataPreProcessing analyticsData="analysis_preprocess" />
      <ReportingTable analyticsData="analysis_output" />
      <AdditionalReportingTable analyticsData="other_output" />
    </>
  );
};

export default AddReporting;
