import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Error from '../pages/Error/Error';
import CreatePipeline from '../pages/CreatePipeline/CreatePipeline';
import EditPipeline from '../pages/EditPipeline/EditPipeline';
import { Routes, Route } from 'react-router-dom';
import { getCookieWithDecryption } from '../utils/crypto';
import ProductUserGuide from '../pages/ProductUserGuide/ProductUserGuide';
import { productUserGuideURLPath } from '../pages/ProductUserGuide/consts';

const PageRoutes = () => {
  const location = useLocation();
  return (
    <Routes>
      {!getCookieWithDecryption('access') ? (
        <Navigate to="/" />
      ) : (
        <>
          <Route path="/" element={<Home />} />
          <Route
            path="/create-pipeline"
            element={
              <CreatePipeline
                LoadUrl={location.state ? location.state.LoadUrl : ''}
                filePath={location.state ? location.state.filePath : ''}
              />
            }
          />
          <Route path="/edit-pipeline" element={<EditPipeline />} />
          <Route
            path={`/${productUserGuideURLPath}`}
            element={<ProductUserGuide />}
          />
          <Route path="*" element={<Error />} />
        </>
      )}
    </Routes>
  );
};

export default PageRoutes;
