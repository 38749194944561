import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTransformations } from '../services/transformations';
import {
  TransformationState,
  Transformation,
  Field,
} from '../types/transformationType';

const initialState: TransformationState = {
  transformations: [],
  transformationsFields: [],
  loading: false,
  error: '',
};

export const fetchTransformations = createAsyncThunk(
  'transformationsApi',
  async () => {
    return await getTransformations();
  },
);

export const transformationSlice = createSlice({
  name: 'transformation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTransformations.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchTransformations.fulfilled, (state, action) => {
      state.loading = false;
      state.transformations = action.payload as Transformation[];

      state.transformationsFields = (action.payload as Transformation[])
        ?.map((transformation: { fields: Field }) => transformation.fields)
        .sort((a: Field, b: Field) => a.name.localeCompare(b.name));

      state.error = '';
    });

    builder.addCase(fetchTransformations.rejected, (state, action) => {
      state.loading = false;
      state.transformations = [];
      state.transformationsFields = [];
      state.error = action.error.message || '';
    });
  },
});

export default transformationSlice.reducer;
