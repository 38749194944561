// @ts-nocheck
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import CopyFile from '../CopyFile/CopyFile';
import { IconButton, Tooltip } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { File } from '../../types/fileType';
import { Field } from '../../types/transformationType';
import { TRANSFORMATIONS, VALIDATIONS } from '../../constants/file';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useSelector, useDispatch } from 'react-redux';
import AddFinalStep from '../AddFinalStep/AddFinalStep';
import { updateFormField } from '../../slices/formFieldSlice';
import {
  setExpandedAccordions,
  toggleAccordion,
} from '../../slices/accordionSlice';
import './FinalAccordion.css';

const FinalAccordion = (props: any) => {
  const { finalItem, list, finalItemIndex, uid } = props;
  const dispatch = useDispatch();
  const [hoveredStep, setHoveredStep] = useState(-1);
  const [selectedFileIndex, setSelectedFileIndex] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>({});
  const filesSelector = useSelector((state: RootState) => state.files.files);
  const formFieldDataTypeItem = useSelector(
    (state) => state.formField[list]?.[finalItem.name],
  );
  const expandedAccordions = useSelector(
    (state) => state.accordion.expandedAccordions,
  );

  const accordionId = uid;
  const isAccordionExpanded = expandedAccordions.includes(uid);
  const AddFile = (e: any, index: number = formFieldDataTypeItem.length) => {
    e.preventDefault();
    const updatedValues = structuredClone(formFieldDataTypeItem);

    updatedValues.splice(index, 0, {
      name: '',
      description: '',
      arguments: [],
      uid: crypto.randomUUID(),
    });

    dispatch(
      updateFormField({ path: [list, finalItem.name], value: updatedValues }),
    );
  };

  const copyFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (selectedFile.file_name != null) {
      let updatedValues = structuredClone(formFieldDataTypeItem);
      let editedSelectedFile = structuredClone(selectedFile);
      editedSelectedFile.uid = crypto.randomUUID();

      if (list === 'final_transformation') {
        editedSelectedFile.transformation =
          editedSelectedFile.transformation.map((transformation) => {
            transformation.uid = crypto.randomUUID();
            return transformation;
          });
        updatedValues = structuredClone([
          ...updatedValues,
          ...editedSelectedFile?.transformation,
        ]);
      } else if (list === 'final_validation') {
        editedSelectedFile.validation = editedSelectedFile.validation.map(
          (validation) => {
            validation.uid = crypto.randomUUID();
            return validation;
          },
        );
        updatedValues = structuredClone([
          ...updatedValues,
          ...editedSelectedFile?.validation,
        ]);
      }

      dispatch(
        updateFormField({ path: [list, finalItem.name], value: updatedValues }),
      );
      enqueueSnackbar(
        `Transformations copied from ${selectedFile.file_name} successfully`,
        {
          variant: 'success',
        },
      );
    }
    setSelectedFileIndex('');
    setSelectedFile({});
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempSelectedFileIndex = Number(
      Object.keys(filesSelector).find(
        (index) => index === event.target.value.toString(),
      ),
    );

    setSelectedFileIndex(event.target.value);
    setSelectedFile(filesSelector[tempSelectedFileIndex]);
  };

  const collapseAll = () => {
    const newArray = formFieldDataTypeItem?.map((item) => item.uid);
    const filteredAccordions = expandedAccordions.filter(
      (accordion) => !newArray.includes(accordion),
    );

    dispatch(setExpandedAccordions(filteredAccordions));
  };

  const expandAll = () => {
    const newArray = [];
    formFieldDataTypeItem.forEach((item) => {
      if (!expandedAccordions.includes(item.uid)) {
        newArray.push(item.uid);
      }
    });
    dispatch(setExpandedAccordions([...expandedAccordions, ...newArray]));
  };

  const getStepIndex = (index) => {
    setHoveredStep(index);
  };

  return (
    <MuiAccordion
      id={uid}
      expanded={isAccordionExpanded}
      onChange={() => dispatch(toggleAccordion(uid))}
    >
      <MuiAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ArrowForwardIosSharpIcon />}
      >
        <Typography>
          {list === 'final_transformation' ? 'Transformations' : 'Validations'}
        </Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        {/* add step at the beginning */}
        <div className="add-file-div">
          <div className="add-button-div">
            <p className="bold">
              {list === 'final_transformation'
                ? 'Add Transformations'
                : 'Add Validations'}
            </p>
            <button
              className="add-file-button"
              onClick={(e) => AddFile(e, 0)}
              type="button"
              disabled={finalItem.name === ''}
            >
              <AddCircleSharpIcon />
            </button>
          </div>
          <Tooltip title="Collapse All">
            <IconButton onClick={collapseAll}>
              <UnfoldLessIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Expand All">
            <IconButton onClick={expandAll}>
              <UnfoldMoreIcon />
            </IconButton>
          </Tooltip>
          {/* copy steps from another file */}
          <CopyFile
            selectedValue={selectedFileIndex}
            onChangeSelect={handleSelectChange}
            selectItems={filesSelector}
            onSubmitButton={copyFiles}
            isDisabledSubmit={
              finalItem.name === '' || selectedFile.file_name === undefined
            }
          />
        </div>
        <div>
          <div className="col-div">
            {isAccordionExpanded &&
              Array.isArray(formFieldDataTypeItem) &&
              formFieldDataTypeItem?.map((item: Field, index: number) => {
                return (
                  <div
                    key={item.uid}
                    // get step index on hover
                    onMouseEnter={() => getStepIndex(index)}
                    onMouseLeave={() => getStepIndex(-1)}
                  >
                    <MuiAccordion
                      id={item.uid}
                      key={item.uid}
                      onChange={() => dispatch(toggleAccordion(item.uid))}
                      expanded={expandedAccordions.includes(item.uid)}
                    >
                      <MuiAccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={<ArrowForwardIosSharpIcon />}
                      >
                        <Typography>
                          {TRANSFORMATIONS[item.name] ||
                            VALIDATIONS[item.name] ||
                            item.name
                              .replace(/_/g, ' ')
                              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                letter.toUpperCase(),
                              )}
                        </Typography>
                      </MuiAccordionSummary>
                      <MuiAccordionDetails>
                        <AddFinalStep
                          colItem={item}
                          colIndex={index}
                          finalItem={finalItem}
                          list={list}
                        />
                      </MuiAccordionDetails>
                    </MuiAccordion>
                    {/* Add step at index */}
                    <div
                      className={`step-add-button ${
                        index !== formFieldDataTypeItem?.length - 1 &&
                        hoveredStep === index
                          ? ''
                          : 'hide-step-add-button'
                      } ${
                        index === formFieldDataTypeItem?.length - 1
                          ? 'last-step-add-button'
                          : ''
                      }`}
                    >
                      <button
                        className="add-file-button"
                        onClick={(e) => AddFile(e, index + 1)}
                        type="button"
                      >
                        <AddCircleSharpIcon />
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        {/* add step at the end */}
        {formFieldDataTypeItem?.length ? (
          <div className="add-file-div">
            <button
              className="add-file-button"
              onClick={(e) => AddFile(e)}
              type="button"
            >
              <AddCircleSharpIcon />
            </button>
          </div>
        ) : (
          ''
        )}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default FinalAccordion;
