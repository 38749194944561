import http from './http';

export const getBlobs = () => {
  return http.get('/api/blobs/');
};

export const getBlob = (filePath: string) => {
  return http.get(`/api/blob/${filePath}`);
};

export const addBlob = (data: any) => {
  return http.post('/api/blob/', data);
};
