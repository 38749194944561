// @ts-nocheck
import { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import DataTabs from '../../components/DataTabs/DataTabs';
import ReviewTable from '../ReviewTable/ReviewTable';
import { FinalStep } from '../../types/finalStepType';
import { DATA_TYPE } from '../../constants/file';
import {
  setExpandedAccordions,
  toggleAccordion,
} from '../../slices/accordionSlice';

import './ReviewDetails.css';
import { useDispatch, useSelector } from 'react-redux';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.5px solid #7d7d7d`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : '#dedede',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ReviewDetails = (props: any) => {
  const [expanded, setExpanded] = useState<string | false>(
    'reviewDetails-clientDetails',
  );
  const [finalSteps, setFinalSteps] = useState<FinalStep[]>([]);
  const dispatch = useDispatch();
  const acquirerDataSources = useSelector(
    (state) => state.formField.acquirer_data_sources,
  );
  const finalTransformation = useSelector(
    (state) => state.formField.final_transformation,
  );
  const finalValidation = useSelector(
    (state) => state.formField.final_validation,
  );
  const engagementName =
    useSelector((state) => state.formField.engagement_name) || 'Not Provided';
  const multirun =
    useSelector((state) => state.formField.multirun) || 'Not Provided';
  const clientName =
    useSelector((state) => state.formField.client_name) || 'Not Provided';
  const clientEmail =
    useSelector((state) => state.formField.client_email) || 'Not Provided';
  const engagementDetails = useSelector(
    (state) => state.formField.engagement_details,
  );
  const engagementTeamDataSources = useSelector(
    (state) => state.formField.engagement_team_data_sources,
  );
  const merchantDataSources = useSelector(
    (state) => state.formField.merchant_data_sources,
  );
  const clientDataSource = useSelector(
    (state) => state.formField.client_data_sources,
  );
  const thirdPartyDataSources = useSelector(
    (state) => state.formField.third_party_data_sources,
  );
  const expandedAccordions = useSelector(
    (state: any) => state.accordion.expandedAccordions,
  );

  useEffect(() => {
    if (!expandedAccordions.includes(`reviewDetails-clientDetails`)) {
      dispatch(setExpandedAccordions([...expandedAccordions, expanded]));
      setExpanded(false);
    }
  }, []);

  useEffect(() => {
    let tempFinalSteps = [] as any[];

    Object.keys(finalTransformation).forEach((item) => {
      if (!tempFinalSteps.find((step: FinalStep) => step.name === item)) {
        tempFinalSteps = [
          ...tempFinalSteps,
          {
            name: item,
            final_transformations: structuredClone(finalTransformation[item]),
            final_validations: finalValidation[item],
          },
        ];
      }
    });

    setFinalSteps(tempFinalSteps);
  }, []);

  const prepareValueToShow = (value, emptyMessage = 'Not Provided') => {
    if (Array.isArray(value) && value.length) {
      return value.join(', ');
    }

    if (typeof value === 'string' && value) {
      return value;
    }

    return emptyMessage;
  };

  return (
    <div>
      <Accordion
        id={`reviewDetails-clientDetails`}
        expanded={
          expandedAccordions.includes(`reviewDetails-clientDetails`) ||
          expanded === 'reviewDetails-clientDetails'
        }
        onChange={() =>
          dispatch(toggleAccordion(`reviewDetails-clientDetails`))
        }
      >
        <AccordionSummary
          aria-controls="clientDetails-content"
          id="clientDetails-header"
        >
          <Typography>Client details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="align-right">
            <Button
              color="error"
              onClick={() => props.setActiveStep(0)}
              sx={{ mr: 1 }}
            >
              Edit
            </Button>
          </div>
          <List disablePadding>
            <ListItem className="list-item-class">
              <ListItemText
                primary="Engagement Name"
                secondary={engagementName}
              />
              <ListItemText
                primary="Will the pipeline be run multiple times?"
                secondary={multirun}
              />
            </ListItem>
            <Divider />
            <ListItem className="list-item-class">
              <ListItemText primary="Client Name" secondary={clientName} />
              <ListItemText
                primary="Client Key Contact Email(s)"
                secondary={prepareValueToShow(clientEmail)}
              />
            </ListItem>
            <Divider />
            <ListItem className="list-item-class">
              <ListItemText
                primary="Engagement Leader"
                secondary={
                  engagementDetails.engagement_leader || 'Not Provided'
                }
              />
              <ListItemText
                primary="Engagement Leader Email"
                secondary={
                  engagementDetails.engagement_leader_email || 'Not Provided'
                }
              />
            </ListItem>
            <Divider />
            <ListItem className="list-item-class">
              <ListItemText
                primary="Engagement Manager"
                secondary={
                  engagementDetails.engagement_manager || 'Not Provided'
                }
              />
              <ListItemText
                primary="Engagement Manager Email"
                secondary={
                  engagementDetails.engagement_manager_email || 'Not Provided'
                }
              />
            </ListItem>
            <Divider />
            <ListItem className="list-item-class">
              <ListItemText
                primary="Engagement Start Date"
                secondary={
                  engagementDetails.engagement_start_date?.toString() ||
                  'Not Provided'
                }
              />
              <ListItemText
                primary="Engagement End Date"
                secondary={
                  engagementDetails.engagement_end_date?.toString() ||
                  'Not Provided'
                }
              />
            </ListItem>
            <Divider />
            <ListItem className="list-item-class">
              <ListItemText
                primary="Data Analysis and Reporting Date"
                secondary={prepareValueToShow(
                  engagementDetails.data_ingest_process_schedule,
                )}
              />
              <ListItemText
                primary="Data Provider Email Addresses"
                secondary={prepareValueToShow(
                  engagementDetails?.engagement_team,
                )}
              />
            </ListItem>
            <Divider />
            <ListItem className="list-item-class">
              <ListItemText
                primary="First Reporting Period Start Date"
                secondary={
                  engagementDetails.first_reporting_period_start ||
                  'Not Provided'
                }
              />
              <ListItemText
                primary="First Reporting Period End Date"
                secondary={prepareValueToShow(
                  engagementDetails?.first_reporting_period_end,
                )}
              />
            </ListItem>
            <Divider />
            <ListItem className="list-item-class">
              <ListItemText
                primary="Ingestion Start Date"
                secondary={
                  engagementDetails.ingest_cycle_start?.toString() ||
                  'Not Provided'
                }
              />
              <ListItemText
                primary="Ingestion End Date"
                secondary={
                  engagementDetails.ingest_cycle_end?.toString() ||
                  'Not Provided'
                }
              />
            </ListItem>
            <Divider />
            <ListItem className="list-item-class">
              <ListItemText
                primary="Reporting Cycle"
                secondary={engagementDetails.reporting_cycle || 'Not Provided'}
              />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
      {engagementDetails.data_providers.includes('merchant') && (
        <Accordion
          id={`reviewDetails-merchantDetails`}
          expanded={expandedAccordions.includes(
            `reviewDetails-merchantDetails`,
          )}
          onChange={() =>
            dispatch(toggleAccordion(`reviewDetails-merchantDetails`))
          }
        >
          <AccordionSummary
            aria-controls="merchantDetails-content"
            id="merchantDetails-header"
          >
            <Typography>Merchant details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="align-right">
              <Button
                color="error"
                onClick={() => props.setActiveStep(1)}
                sx={{ mr: 1 }}
              >
                Edit
              </Button>
            </div>
            <List disablePadding>
              {expandedAccordions.includes(`reviewDetails-merchantDetails`) &&
                engagementDetails.data_providers.includes('merchant') &&
                merchantDataSources?.map((item: any, index: any) => {
                  return (
                    <div key={item.uid}>
                      <ListItem
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '0.2fr 1fr 1fr',
                        }}
                      >
                        <ListItemText primary={index + 1} />
                        <ListItemText
                          primary="Merchant Name"
                          secondary={item.merchant_name || 'Not Provided'}
                        />
                        <ListItemText
                          primary="Main Merchant Contact Email(s)"
                          secondary={prepareValueToShow(item?.merchant_email)}
                        />
                      </ListItem>
                    </div>
                  );
                })}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
      {engagementDetails.data_providers.includes('merchant') && (
        <Accordion
          id={`reviewDetails-Merchant-data-source`}
          expanded={expandedAccordions.includes(
            `reviewDetails-Merchant-data-source`,
          )}
          onChange={() =>
            dispatch(toggleAccordion(`reviewDetails-Merchant-data-source`))
          }
        >
          <AccordionSummary
            aria-controls="dataSourceDetails-content"
            id="dataSourceDetails-header"
          >
            <Typography>Merchant data source</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List disablePadding>
              {expandedAccordions.includes(
                `reviewDetails-Merchant-data-source`,
              ) &&
                engagementDetails.data_providers.includes('merchant') &&
                merchantDataSources?.map((item: any, index: any) => {
                  return (
                    <div key={item.uid}>
                      <Accordion
                        id={`reviewDetails-sub-merchant-${item.merchant_name}-${index}`}
                        expanded={expandedAccordions.includes(
                          `reviewDetails-sub-merchant-${item.merchant_name}-${index}`,
                        )}
                        onChange={() =>
                          dispatch(
                            toggleAccordion(
                              `reviewDetails-sub-merchant-${item.merchant_name}-${index}`,
                            ),
                          )
                        }
                      >
                        <AccordionSummary
                          aria-controls="merchantDetails-content"
                          id="merchantDetails-header"
                        >
                          <Typography>{item.merchant_name}</Typography>
                        </AccordionSummary>
                        <div className="align-right">
                          <Button
                            color="error"
                            onClick={() => props.setActiveStep(2)}
                            sx={{ mr: 1 }}
                          >
                            Edit
                          </Button>
                        </div>
                        <AccordionDetails>
                          <DataTabs data={item} key={index} />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
      {engagementDetails.data_providers.includes('client') &&
        clientDataSource?.map((item: any, index: any) => {
          return (
            <div key={item.uid}>
              <Accordion
                id={`reviewDetails-sub-Client-data-source-${item.uid}`}
                expanded={expandedAccordions.includes(
                  `reviewDetails-sub-Client-data-source-${item.uid}`,
                )}
                onChange={() =>
                  dispatch(
                    toggleAccordion(
                      `reviewDetails-sub-Client-data-source-${item.uid}`,
                    ),
                  )
                }
              >
                <AccordionSummary
                  aria-controls="merchantDetails-content"
                  id="merchantDetails-header"
                >
                  <Typography>Client data source</Typography>
                </AccordionSummary>
                <div className="align-right">
                  <Button
                    color="error"
                    onClick={() => props.setActiveStep(3)}
                    sx={{ mr: 1 }}
                  >
                    Edit
                  </Button>
                </div>
                <AccordionDetails>
                  <DataTabs data={item} key={index} />
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      {engagementDetails.data_providers.includes('acquirer') && (
        <Accordion
          id={`reviewDetails-Acquirer-data-source`}
          expanded={expandedAccordions.includes(
            `reviewDetails-Acquirer-data-source`,
          )}
          onChange={() =>
            dispatch(toggleAccordion(`reviewDetails-Acquirer-data-source`))
          }
        >
          <AccordionSummary
            aria-controls="dataSourceDetails-content"
            id="dataSourceDetails-header"
          >
            <Typography>Acquirer data source</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List disablePadding>
              {expandedAccordions.includes(
                `reviewDetails-Acquirer-data-source`,
              ) &&
                engagementDetails.data_providers.includes('acquirer') &&
                acquirerDataSources?.map((item: any, index: any) => {
                  return (
                    <div key={item.uid}>
                      <Accordion
                        id={`reviewDetails-sub-acquirer-${item.acquirer_name}-${index}`}
                        expanded={expandedAccordions.includes(
                          `reviewDetails-sub-acquirer-${item.acquirer_name}-${index}`,
                        )}
                        onChange={() =>
                          dispatch(
                            toggleAccordion(
                              `reviewDetails-sub-acquirer-${item.acquirer_name}-${index}`,
                            ),
                          )
                        }
                      >
                        <AccordionSummary
                          aria-controls="merchantDetails-content"
                          id="merchantDetails-header"
                        >
                          <Typography>{item.acquirer_name}</Typography>
                        </AccordionSummary>
                        <div className="align-right">
                          <Button
                            color="error"
                            onClick={() => props.setActiveStep(5)}
                            sx={{ mr: 1 }}
                          >
                            Edit
                          </Button>
                        </div>
                        <AccordionDetails>
                          <ListItem
                            style={{
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr 1fr',
                            }}
                          >
                            <ListItemText
                              className="overflow"
                              style={{ float: 'left', width: '150px' }}
                              primary="Acquirer Email(s)"
                              secondary={prepareValueToShow(
                                item?.acquirer_email,
                              )}
                            />
                            <ListItemText
                              primary="merchants"
                              secondary={prepareValueToShow(
                                item.merchants,
                                'NA',
                              )}
                            />
                          </ListItem>
                          <DataTabs data={item} key={index} />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
      {engagementDetails.data_providers.includes('engagement_team') &&
        engagementTeamDataSources?.map((item: any, index: any) => {
          return (
            <div key={item.uid}>
              <Accordion
                id={`reviewDetails-Engagement-team-data-source-${index}`}
                expanded={expandedAccordions.includes(
                  `reviewDetails-Engagement-team-data-source-${index}`,
                )}
                onChange={() =>
                  dispatch(
                    toggleAccordion(
                      `reviewDetails-Engagement-team-data-source-${index}`,
                    ),
                  )
                }
              >
                <AccordionSummary
                  aria-controls="merchantDetails-content"
                  id="merchantDetails-header"
                >
                  <Typography>Engagement team data source</Typography>
                </AccordionSummary>
                <div className="align-right">
                  <Button
                    color="error"
                    onClick={() => props.setActiveStep(4)}
                    sx={{ mr: 1 }}
                  >
                    Edit
                  </Button>
                </div>
                <AccordionDetails>
                  <DataTabs data={item} key={index} />
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      {engagementDetails.data_providers.includes('third_party') &&
        thirdPartyDataSources?.map((item: any, index: any) => {
          return (
            <div key={item.uid}>
              <Accordion
                id={`reviewDetails-Third-party-data-source-${index}`}
                expanded={expandedAccordions.includes(
                  `reviewDetails-Third-party-data-source-${index}`,
                )}
                onChange={() =>
                  dispatch(
                    toggleAccordion(
                      `reviewDetails-Third-party-data-source-${index}`,
                    ),
                  )
                }
              >
                <AccordionSummary
                  aria-controls="merchantDetails-content"
                  id="merchantDetails-header"
                >
                  <Typography>Third party data source</Typography>
                </AccordionSummary>
                <div className="align-right">
                  <Button
                    color="error"
                    onClick={() => props.setActiveStep(6)}
                    sx={{ mr: 1 }}
                  >
                    Edit
                  </Button>
                </div>
                <AccordionDetails>
                  <DataTabs data={item} key={index} />
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      {finalTransformation && finalValidation && finalSteps.length > 0 && (
        <Accordion
          id={`reviewDetails-Final-Transformations-and-validations`}
          expanded={expandedAccordions.includes(
            `reviewDetails-Final-Transformations-and-validations`,
          )}
          onChange={() =>
            dispatch(
              toggleAccordion(
                `reviewDetails-Final-Transformations-and-validations`,
              ),
            )
          }
        >
          <AccordionSummary
            aria-controls="dataSourceDetails-content"
            id="dataSourceDetails-header"
          >
            <Typography>Final transformations and validations</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List disablePadding>
              {finalTransformation &&
                finalValidation &&
                finalSteps.length > 0 &&
                finalSteps.map((step: FinalStep, index: number) => {
                  return (
                    <div key={index}>
                      <Accordion
                        id={`reviewDetails-${DATA_TYPE[step.name]}`}
                        expanded={expandedAccordions.includes(
                          `reviewDetails-${DATA_TYPE[step.name]}`,
                        )}
                        onChange={() =>
                          dispatch(
                            toggleAccordion(
                              `reviewDetails-${DATA_TYPE[step.name]}`,
                            ),
                          )
                        }
                      >
                        <AccordionSummary
                          aria-controls="merchantDetails-content"
                          id="merchantDetails-header"
                        >
                          <Typography>{DATA_TYPE[step.name]}</Typography>
                        </AccordionSummary>
                        <div className="align-right">
                          <Button
                            color="error"
                            onClick={() => props.setActiveStep(7)}
                            sx={{ mr: 1 }}
                          >
                            Edit
                          </Button>
                        </div>
                        <AccordionDetails>
                          {step.final_transformations.length > 0 && (
                            <ReviewTable
                              items={step.final_transformations}
                              title="Transformation Name"
                            />
                          )}
                          {step.final_validations.length > 0 && (
                            <ReviewTable
                              items={step.final_validations}
                              title="Validation Name"
                            />
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default ReviewDetails;
