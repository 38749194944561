// @ts-nocheck
import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TagsInput } from '../TagsInput/TagsInput';
import { Divider, Typography } from '@mui/material';
import { DatePicker } from 'antd';
import dateFormat from 'dateformat';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';
import './AddConditionArgumentList.css';

const AddConditionArgumentList = (props: any) => {
  const { analyticsData, dataItemIndex, colIndex, condition, conditionIndex } =
    props;
  const dispatch = useDispatch();

  const pathToConditionArgument = [
    'analytics',
    analyticsData,
    dataItemIndex,
    'conditions_to_exclude',
    colIndex,
    'condition_arguments',
    conditionIndex,
  ];
  const conditionArgument = useSelector(
    (state) =>
      ['formField', ...pathToConditionArgument].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || [],
  );

  const { RangePicker } = DatePicker;

  const dates = [
    `Previous reporting period`,
    `Current reporting period`,
    `Previous reporting period, current reporting period`,
    `Before Date X`,
    `After Date X`,
    `Between Date X and Date Y`,
  ];

  const [selectionValue, setSelectionValue] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState('');

  // reading date_col to show values

  useEffect(() => {
    if (conditionArgument) {
      const updatedConditionArgumentDateCol = structuredClone(
        conditionArgument['date_col'],
      );
      if (updatedConditionArgumentDateCol.includes('before')) {
        setSelectionValue(`Before Date X`);
        setSelectedDate(
          dayjs(updatedConditionArgumentDateCol.split('_').pop(), 'YYYY/MM/DD'),
        );
      } else if (updatedConditionArgumentDateCol.includes('after')) {
        setSelectionValue(`After Date X`);
        setSelectedDate(
          dayjs(updatedConditionArgumentDateCol.split('_').pop(), 'YYYY/MM/DD'),
        );
      } else if (updatedConditionArgumentDateCol.includes('between')) {
        setSelectionValue(`Between Date X and Date Y`);
        setSelectedDateRange([
          dayjs(updatedConditionArgumentDateCol.split('_').pop(), 'YYYY/MM/DD'),
          dayjs(
            updatedConditionArgumentDateCol.split('_').pop().split(',').pop(),
            'YYYY/MM/DD',
          ),
        ]);
      } else {
        setSelectionValue(updatedConditionArgumentDateCol.replace(/_/g, ' '));
      }
    }
  }, [condition]);

  const handleValueChange = (value: string | number, fieldName: string) => {
    const updatedValues = structuredClone(conditionArgument);

    updatedValues[fieldName] = value;
    dispatch(
      updateFormField({
        path: pathToConditionArgument,
        value: updatedValues,
      }),
    );
  };

  const handleSelectedDate = (e: any, fieldName: string) => {
    e.preventDefault();

    setSelectionValue(e.target.value);
    setSelectedDate('');
    setSelectedDateRange('');

    const updatedValues = structuredClone(conditionArgument);
    if (dates.includes(e.target.value)) {
      updatedValues[fieldName] = e.target.value.replace(/ /g, '_');
    } else {
      updatedValues[fieldName] = '';
    }
    dispatch(
      updateFormField({
        path: pathToConditionArgument,
        value: updatedValues,
      }),
    );
  };

  const getSelectedDate = (inputDate) => dateFormat(inputDate, 'isoDate');

  const handleDatePick = (
    value: string | [],
    index: number,
    fieldName: string,
    option: string,
  ) => {
    const updatedValues = structuredClone(conditionArgument);

    let formattedDate;

    switch (option) {
      case 'Before Date X':
        formattedDate = value ? `before_${getSelectedDate(value)}` : '';
        break;
      case 'After Date X':
        formattedDate = value ? `after_${getSelectedDate(value)}` : '';
        break;
      case 'Between Date X and Date Y':
        formattedDate = value
          ? `between_${getSelectedDate(value[0])},${getSelectedDate(value[1])}`
          : '';
        break;
      default:
        break;
    }

    updatedValues[fieldName] = formattedDate;
    dispatch(
      updateFormField({
        path: pathToConditionArgument,
        value: updatedValues,
      }),
    );
  };

  const renderDateSelection = (value: string, index: number) => {
    switch (value) {
      case `Before Date X`:
        return (
          <DatePicker
            value={selectedDate ? selectedDate : ''}
            onChange={(date) => {
              setSelectedDate(date);
              handleDatePick(date, index, 'date_col', 'Before Date X');
            }}
            format="DD/MM/YYYY"
            size="large"
            inputReadOnly
          />
        );
      case `After Date X`:
        return (
          <DatePicker
            value={selectedDate ? selectedDate : ''}
            onChange={(date) => {
              setSelectedDate(date);
              handleDatePick(date, index, 'date_col', 'After Date X');
            }}
            format="DD/MM/YYYY"
            size="large"
            inputReadOnly
          />
        );
      case `Between Date X and Date Y`:
        return (
          <RangePicker
            value={selectedDateRange ? selectedDateRange : ''}
            onChange={(date) => {
              setSelectedDateRange(date);
              handleDatePick(
                date,
                index,
                'date_col',
                'Between Date X and Date Y',
              );
            }}
            format="DD/MM/YYYY"
            size="large"
            inputReadOnly
          />
        );

      default:
        return '';
    }
  };

  return (
    <div>
      {condition['merchant_id_col'] && (
        <div style={{ marginBottom: '10px' }}>
          <Typography>Merchant names/IDs to exclude</Typography>
          <TagsInput
            required
            type="text"
            value={condition['merchant_id_col']}
            onChange={(e: any) => handleValueChange(e, 'merchant_id_col')}
            name="merchants"
            placeHolder="enter merchant name/ID"
          />
          <em>press enter to add merchant name/ID</em>
        </div>
      )}
      <Divider />
      {condition['acquirer_col'] && (
        <div style={{ margin: '10px 0' }}>
          <Typography>Acquirer names to exclude</Typography>
          <TagsInput
            required
            type="text"
            value={condition['acquirer_col']}
            onChange={(e: any) => handleValueChange(e, 'acquirer_col')}
            name="acquirers"
            placeHolder="enter acquirer name"
          />
          <em>press enter to add new acquirer name</em>
        </div>
      )}
      <Divider />
      {
        <div style={{ marginTop: '10px' }}>
          <Typography>Excluded period:</Typography>
          <FormControl required fullWidth sx={{ marginTop: '10px' }}>
            <InputLabel id="demo-simple-select-label1">
              Select Period
            </InputLabel>
            <Select
              required
              labelId="demo-simple-select-label1"
              id="demo-simple-select1"
              value={selectionValue ? selectionValue : ''}
              label="Select a analytics"
              name="analytics"
              defaultValue="rename_column"
              variant="standard"
              onChange={(e) => handleSelectedDate(e, 'date_col')}
            >
              {dates.length > 0 &&
                dates.map((item: Condition, index: number) => {
                  return (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {renderDateSelection(selectionValue, conditionIndex)}
        </div>
      }
    </div>
  );
};

export default AddConditionArgumentList;
