// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';

export const accordionSlice = createSlice({
  name: 'accordion',
  initialState: {
    expandedAccordions: [],
  },
  reducers: {
    setExpandedAccordions: (state, action) => {
      state.expandedAccordions = action.payload;
    },
    toggleAccordion: (state, action) => {
      const accordionId = action.payload;
      if (state.expandedAccordions.includes(accordionId)) {
        state.expandedAccordions = state.expandedAccordions.filter(
          (id) => id !== accordionId,
        );
      } else {
        state.expandedAccordions.push(accordionId);
      }
    },
  },
});

export const { setExpandedAccordions, toggleAccordion } =
  accordionSlice.actions;

export default accordionSlice.reducer;
