// @ts-nocheck
import { AddCircleSharp } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  getDataType,
  getPlaceholder,
  getObjKeyName,
  getInputValue,
  getPathToArguments,
  getNewValue,
} from '../../utils/inputs';
import NumericTextField from '../NumericTextField/NumericTextField';
import InputSelectType from './InputSelectType';
import ButtonRemove from './ButtonRemove';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';

export default function SubList({
  subList,
  inputProps,
  index,
  item,
  listIndex,
  contextHandleDataTypeChange,
}) {
  const dispatch = useDispatch();
  const objKey = getObjKeyName(item);
  const path = getPathToArguments({ contextHandleDataTypeChange });
  const attribute = useSelector(
    (state) =>
      ['formField', ...path, index, objKey, listIndex].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || {},
  );

  const handleSubListValueChange = (
    value: any,
    subListKey: string,
    dataType: any,
  ) => {
    const input = getInputValue({ python_datatype: dataType }, value);
    const path = getPathToArguments({
      contextHandleDataTypeChange,
      addToPath: [index, objKey, listIndex, subListKey, 'value'],
    });
    dispatch(updateFormField({ path, value: input }));
  };

  const handleNewSubListValue = () => {
    const path = getPathToArguments({
      contextHandleDataTypeChange,
      addToPath: [index, objKey, listIndex, crypto.randomUUID()],
    });
    dispatch(
      updateFormField({ path, value: { value: '', python_datatype: 'str' } }),
    );
  };

  const handleRemoveSubListValue = (subListKey: string) => {
    const path = getPathToArguments({
      contextHandleDataTypeChange,
      addToPath: [index, objKey, listIndex],
    });
    const editedAttribute = structuredClone(attribute);

    if (Array.isArray(editedAttribute)) {
      editedAttribute.splice(subListKey, 1);
    } else if (subListKey in editedAttribute) {
      delete editedAttribute[subListKey];
    }
    dispatch(updateFormField({ path, value: editedAttribute }));
  };

  const handleDataTypeInSubListChange = (newType, subListKey) => {
    const path = getPathToArguments({
      contextHandleDataTypeChange,
      addToPath: [index, objKey, listIndex, subListKey],
    });
    const editedAttributeSubList = structuredClone(attribute?.[subListKey]);
    const value = editedAttributeSubList.value;
    editedAttributeSubList.value = getNewValue(
      newType,
      value,
      editedAttributeSubList.python_datatype,
    );
    editedAttributeSubList.python_datatype = newType;
    dispatch(updateFormField({ path, value: editedAttributeSubList }));
  };

  return (
    <Box sx={{ ml: 8 }}>
      <Box
        component="button"
        ml={4}
        className="add-file-button"
        onClick={handleNewSubListValue}
        type="button"
        data-testid="3866217b-8bf9-4cb2-8c15-6d1bc553ad2e2"
        sx={{ mb: 1 }}
      >
        <AddCircleSharp />
        <Box component="span" ml={0.5}>
          Add value
        </Box>
      </Box>
      {Object.keys(subList)?.map((subListKey: any, subListIndex) => {
        const subListItem = subList[subListKey];
        const subListValue = subListItem.value;
        const subListDataType = subListItem.python_datatype;
        const label = subListIndex + 1;
        return (
          <Box
            key={subListKey}
            display="flex"
            gap={1}
            alignItems="center"
            mb={1}
            className="cmp-list__item"
          >
            {getDataType(subListDataType) === 'boolean' ? (
              <InputSelectType
                label={label}
                value={subListValue?.toString()}
                handleOnChange={(e) =>
                  handleSubListValueChange(
                    e.target.value,
                    subListKey,
                    subListDataType,
                  )
                }
                formControlProps={{
                  required: true,
                  sx: { ml: 4 },
                }}
                selectProps={{
                  type: subListDataType,
                  required: true,
                  sx: { minWidth: 167 },
                  'data-testid': '21a9708e-0603-42f5-99dd-4ea0f75873212',
                }}
                listType="dataTypeBoolean"
              />
            ) : (
              subListDataType !== 'list' && (
                <NumericTextField
                  required
                  type={getDataType(subListDataType)}
                  label={label}
                  variant="standard"
                  value={subListValue?.toString()}
                  onChange={(e) =>
                    handleSubListValueChange(
                      e.target.value,
                      subListKey,
                      subListDataType,
                    )
                  }
                  sx={{
                    ml: 4,
                  }}
                  inputProps={inputProps}
                  placeholder={getPlaceholder(subListDataType)}
                  data-testid="2257dd0e-e300-492f-ad2a-681c6fa53bb92"
                  dataType={subListDataType}
                />
              )
            )}
            <InputSelectType
              value={subListDataType}
              handleOnChange={(e) =>
                handleDataTypeInSubListChange(e.target.value, subListKey)
              }
              formControlProps={{
                sx: { ml: 4 },
              }}
              selectProps={{
                sx: { minWidth: 110 },
                'data-testid': '3beb8264-4517-466f-ae4b-b5084f4f601c2',
              }}
              listType="dataTypeBasic"
            />
            <ButtonRemove
              label="Remove value"
              onRemove={() => handleRemoveSubListValue(subListKey)}
              buttonProps={{
                'data-testid': '1addf90f-ef2a-4053-a943-3b867ed554532',
              }}
              isDisabled={Object.keys(subList).length === 1}
              className="remove-merchant-button cmp-list__btn-remove-item"
            />
          </Box>
        );
      })}
    </Box>
  );
}
