// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _set from 'lodash/set';
dayjs.extend(utc);

const initialState = {
  client_name: '',
  client_email: '',
  multirun: '',
  engagement_name: '',
  engagement_details: {
    engagement_leader: '',
    engagement_leader_email: '',
    engagement_manager: '',
    engagement_manager_email: '',
    engagement_start_date: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss+0000'),
    engagement_end_date: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss+0000'),
    engagement_team: [],
    data_providers: [],
    data_request_cycle: '0 9 28 * *',
    data_chasing_start: '0 9 4 * *',
    data_chasing_cycle: '0 9 * * 1,3',
    data_chasing_end: '0 9 11 * *',
    ingest_cycle_start: '0 9 4 * *',
    ingest_cycle_end: '0 9 11 * *',
    first_reporting_period_start: dayjs()
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss+0000'),
    first_reporting_period_end: dayjs()
      .utc()
      .format('YYYY-MM-DDTHH:mm:ss+0000'),
    data_ingest_process_schedule: '0 16 11 * *',
    reporting_cycle: '',
    m4tfu_landing_zone_cleanup_cycle: '0 9 27 * *',
    data_request_merchant_email_template: '',
    data_chaser_merchant_email_template: '',
    data_request_acquirer_email_template: '',
    data_chaser_acquirer_email_template: '',
  },
  merchant_data_sources: [
    {
      merchant_name: '',
      merchant_email: '',
      uid: crypto.randomUUID(),
      data_source_type: [
        {
          uid: crypto.randomUUID(),
          selected_type: '',
        },
      ],
    },
  ],
  client_data_sources: [
    {
      uid: crypto.randomUUID(),
      data_source_type: [
        {
          uid: crypto.randomUUID(),
          selected_type: '',
        },
      ],
    },
  ],
  engagement_team_data_sources: [
    {
      uid: crypto.randomUUID(),
      data_source_type: [
        {
          uid: crypto.randomUUID(),
          selected_type: '',
        },
      ],
    },
  ],
  acquirer_data_sources: [
    {
      uid: crypto.randomUUID(),
      acquirer_name: '',
      acquirer_id: '',
      merchants: [],
      data_source_type: [
        {
          uid: crypto.randomUUID(),
          selected_type: '',
        },
      ],
    },
  ],
  third_party_data_sources: [
    {
      uid: crypto.randomUUID(),
      data_source_type: [
        {
          uid: crypto.randomUUID(),
          selected_type: '',
        },
      ],
    },
  ],
  final_transformation: [],
  final_validation: [],
  analytics: {
    analysis_preprocess: [],
    analysis_output: [],
    other_output: [],
  },
  final_data: [],
};

const formFieldSlice = createSlice({
  name: 'formField',
  initialState,
  reducers: {
    resetState(state, action) {
      return structuredClone(action.payload);
    },
    updateMerchantDataSources(state, action) {
      state.merchant_data_sources = structuredClone(action.payload.data);
    },
    updateAcquirerDataSources(state, action) {
      state.acquirer_data_sources = structuredClone(action.payload.data);
    },
    updateClientDataSources(state, action) {
      state.client_data_sources = structuredClone(action.payload.data);
    },
    updateAnalytics(state, action) {
      state.analytics = structuredClone(action.payload.data);
    },
    updateFinalTransformation(state, action) {
      state.final_transformation = structuredClone(action.payload.data);
    },
    updateFinalValidation(state, action) {
      state.final_validation = structuredClone(action.payload.data);
    },
    updateEngagementDetails(state, action) {
      state.engagement_details = structuredClone(action.payload.data);
    },
    updateEngagementTeamDataSources(state, action) {
      state.engagement_team_data_sources = structuredClone(action.payload.data);
    },
    updateThirdPartyDataSources(state, action) {
      state.third_party_data_sources = structuredClone(action.payload.data);
    },
    updateFinalData(state, action) {
      state.final_data = structuredClone(action.payload.data);
    },
    updateFormField(state, action) {
      const { key, path, value } = action.payload;

      if (key) {
        state[key] = structuredClone(value);
      } else if (path && Array.isArray(action.payload.path) && path.length) {
        _set(state, path, structuredClone(value));
      }
    },
    updateFormFields(state, action) {
      const { keys, paths, values } = action.payload;
      if (keys && Array.isArray(keys) && keys.length) {
        keys.forEach((key, index) => {
          state[key] = structuredClone(values[index]);
        });
      } else if (paths && Array.isArray(paths) && paths.length) {
        paths.forEach((path, index) => {
          _.set(state, path, structuredClone(values[index]));
        });
      }
    },
  },
});

export const {
  updateMerchantDataSources,
  updateAcquirerDataSources,
  updateClientDataSources,
  updateAnalytics,
  updateFinalTransformation,
  updateFinalValidation,
  updateEngagementDetails,
  updateEngagementTeamDataSources,
  updateThirdPartyDataSources,
  updateFormField,
  updateFinalData,
  updateFormFields,
  resetState,
} = formFieldSlice.actions;
export default formFieldSlice.reducer;
