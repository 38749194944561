import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormFields } from '../../slices/formFieldSlice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'client',
  'merchant',
  'acquirer',
  'engagement_team',
  'third_party',
];

export default function MultipleSelectCheckbox() {
  const dispatch = useDispatch();
  const merchantDataSources = useSelector(
    (state: any) => state.formField.merchant_data_sources,
  );
  const engagementDetails = useSelector(
    (state: any) => state.formField.engagement_details,
  );

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;

    const merchantSelected = Array.isArray(value) && value.includes('merchant');

    const merchant_engagement_details: {
      data_request_merchant_email_template: string;
      data_chaser_merchant_email_template: string;
    } = {
      data_request_merchant_email_template: merchantSelected
        ? engagementDetails.data_request_merchant_email_template
        : '',
      data_chaser_merchant_email_template: merchantSelected
        ? engagementDetails.data_chaser_merchant_email_template
        : '',
    };

    const merchant_data_sources = merchantSelected
      ? merchantDataSources
      : [
          {
            uid: crypto.randomUUID(),
            merchant_name: '',
            merchant_email: '',
            data_source_type: [
              {
                uid: crypto.randomUUID(),
                selected_type: '',
              },
            ],
          },
        ];

    dispatch(
      updateFormFields({
        keys: ['engagement_details', 'merchant_data_sources'],
        values: [
          {
            ...structuredClone(engagementDetails),
            ...merchant_engagement_details,
            data_providers:
              typeof value === 'string' ? value.split(',') : value,
          },
          [...merchant_data_sources],
        ],
      }),
    );
  };

  const getName = (name: any) => {
    switch (name) {
      case 'client':
        return 'Client';
      case 'merchant':
        return 'Merchant';
      case 'acquirer':
        return 'Acquirer';
      case 'engagement_team':
        return 'Engagement team';
      case 'third_party':
        return 'Third party';
      default:
        return;
    }
  };

  return (
    <div>
      <FormControl
        required
        sx={{ m: 1, margin: '0 !important', width: '100%' }}
      >
        <InputLabel id="demo-multiple-checkbox-label">
          Select types of data providers
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          required
          value={engagementDetails.data_providers}
          onChange={handleChange}
          input={<OutlinedInput label="Select types of data providers" />}
          renderValue={(selected: any) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value: any) => (
                <Chip key={value} label={getName(value)} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names &&
            names.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox
                  checked={engagementDetails.data_providers.indexOf(name) > -1}
                />
                <ListItemText primary={getName(name)} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
