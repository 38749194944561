// @ts-nocheck
import React from 'react';
import EmailTemplate from '../EmailTemplate/EmailTemplate';
import { useSelector } from 'react-redux';

import './AddEmailTemplates.css';

const AddEmailTemplates = () => {
  const engagementDetails = useSelector(
    (state) => state.formField.engagement_details,
  );
  return (
    <div>
      {engagementDetails.data_providers.includes('merchant') && (
        <div>
          <EmailTemplate
            title="Data Requests to Merchants"
            dataKey="data_request_merchant_email_template"
          />
          <EmailTemplate
            title="Data Chasers to Merchants"
            dataKey="data_chaser_merchant_email_template"
          />
        </div>
      )}
      {engagementDetails.data_providers.includes('acquirer') && (
        <div>
          <EmailTemplate
            title="Data Requests to Acquirers"
            dataKey="data_request_acquirer_email_template"
          />
          <EmailTemplate
            title="Data Chasers to Acquirers"
            dataKey="data_chaser_acquirer_email_template"
          />
        </div>
      )}
    </div>
  );
};

export default AddEmailTemplates;
