import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

const cypherKey = process.env.REACT_APP_CYPHER_KEY || '';

export const setCookieWithEncryption = <Type>(
  cookieKey: string,
  cookieValue: Type,
  options?: Cookies.CookieAttributes | undefined,
) => {
  const encryptedCookieValue = CryptoJS.AES.encrypt(
    JSON.stringify(cookieValue),
    cypherKey,
  ).toString();

  options
    ? Cookies.set(cookieKey, encryptedCookieValue, options)
    : cookieKey === 'access'
      ? Cookies.set(cookieKey, encryptedCookieValue)
      : Cookies.set(cookieKey, encryptedCookieValue, {
          sameSite: 'strict',
          secure: true,
        });
};

export const getCookieWithDecryption = <Type>(
  cookieKey: string,
): Type | null => {
  const encryptedCookieValue: string = Cookies.get(cookieKey) || '';

  if (!encryptedCookieValue) {
    return null;
  }

  const decryptedCookieValue = CryptoJS.AES.decrypt(
    encryptedCookieValue,
    cypherKey,
  ).toString(CryptoJS.enc.Utf8);

  return JSON.parse(decryptedCookieValue);
};
