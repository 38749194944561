// @ts-nocheck
import { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Condition } from '../../types/analyticsType';
import AddPreProcessing from '../AddPreProcessing/AddPreProcessing';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';
import {
  toggleAccordion,
  setExpandedAccordions,
} from '../../slices/accordionSlice';
import './AddPreProcessingData.css';

const AddPreProcessingData = (props: any) => {
  const { analyticsData, dataItemIndex, uid } = props;
  const dispatch = useDispatch();
  const pathToConditionsToExclude = [
    'analytics',
    analyticsData,
    dataItemIndex,
    'conditions_to_exclude',
  ];
  const analyticsConditionsToExclude = useSelector(
    (state) =>
      ['formField', ...pathToConditionsToExclude].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || [],
  );
  const expandedAccordions = useSelector(
    (state) => state.accordion.expandedAccordions,
  );
  const [hoveredKPI, setHoveredKPI] = useState(-1);

  const AddPreProcessingStep = (
    e: any,
    index: number = analyticsConditionsToExclude.length,
  ) => {
    e.preventDefault();

    const updatedValues = structuredClone(analyticsConditionsToExclude);

    updatedValues.splice(index, 0, {
      name: '',
      condition_arguments: [],
      uid: crypto.randomUUID(),
    });
    dispatch(
      updateFormField({
        path: pathToConditionsToExclude,
        value: updatedValues,
      }),
    );
  };

  const collapseAll = () => {
    const newArray = analyticsConditionsToExclude.map(
      (item, index) => item.uid,
    );
    const filteredAccordions = expandedAccordions.filter(
      (accordion) => !newArray.includes(accordion),
    );

    dispatch(setExpandedAccordions(filteredAccordions));
  };

  const expandAll = () => {
    const newArray = [];

    analyticsConditionsToExclude.forEach((item, index) => {
      if (!expandedAccordions.includes(item.uid)) {
        newArray.push(item.uid);
      }
    });
    dispatch(setExpandedAccordions([...expandedAccordions, ...newArray]));
  };

  const getStepIndex = (index) => {
    setHoveredKPI(index);
  };

  const isAccordionExpanded = expandedAccordions.includes(uid);

  return (
    <MuiAccordion
      id={uid}
      expanded={isAccordionExpanded}
      onChange={() => dispatch(toggleAccordion(uid))}
    >
      <MuiAccordionDetails>
        {/* add step at the beginning */}
        <div className="add-file-div">
          <div className="add-button-div">
            <p className="add-reporting-kpi-btn">Add Pre-processing</p>
            <button
              className="add-file-button"
              onClick={(e) => AddPreProcessingStep(e, 0)}
              type="button"
            >
              <AddCircleSharpIcon />
            </button>
          </div>
          <Tooltip title="Collapse All" sx={{ margin: '0 10px' }}>
            <IconButton onClick={collapseAll}>
              <UnfoldLessIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Expand All">
            <IconButton onClick={expandAll}>
              <UnfoldMoreIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <div className="col-div">
            {analyticsConditionsToExclude?.map(
              (item: Condition, index: number) => {
                return (
                  <div
                    key={item.uid}
                    // get step index on hover
                    onMouseEnter={() => getStepIndex(index)}
                    onMouseLeave={() => getStepIndex(-1)}
                  >
                    <MuiAccordion
                      id={item.uid}
                      key={item.uid}
                      onChange={() => dispatch(toggleAccordion(item.uid))}
                      expanded={expandedAccordions.includes(item.uid)}
                    >
                      <MuiAccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={<ArrowForwardIosSharpIcon />}
                      >
                        <Typography>{item.name}</Typography>
                      </MuiAccordionSummary>
                      <MuiAccordionDetails>
                        <AddPreProcessing
                          analyticsData={analyticsData}
                          colIndex={index}
                          dataItemIndex={dataItemIndex}
                        />
                      </MuiAccordionDetails>
                    </MuiAccordion>
                    {/* Add step at index */}
                    <div
                      className={`step-add-button ${
                        index !== analyticsConditionsToExclude?.length - 1 &&
                        hoveredKPI === index
                          ? ''
                          : 'hide-step-add-button'
                      } ${
                        index === analyticsConditionsToExclude?.length - 1
                          ? 'last-step-add-button'
                          : ''
                      }`}
                    >
                      <button
                        className="add-file-button"
                        onClick={(e) => AddPreProcessingStep(e, index + 1)}
                        type="button"
                      >
                        <AddCircleSharpIcon />
                      </button>
                    </div>
                  </div>
                );
              },
            )}
          </div>
        </div>
        {/* add step at the end */}
        {analyticsConditionsToExclude?.length ? (
          <div className="add-file-div">
            <button
              className="add-file-button"
              onClick={(e) => AddPreProcessingStep(e)}
              type="button"
            >
              <AddCircleSharpIcon />
            </button>
          </div>
        ) : (
          ''
        )}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default AddPreProcessingData;
