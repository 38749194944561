// @ts-nocheck
import * as React from 'react';
import './AddArgumentList.css';
import Box from '@mui/material/Box';
import ItemBoolean from '../Form/ItemBoolean';
import ItemList from '../Form/ItemList';
import ItemDict from '../Form/ItemDict';
import ItemElse from '../Form/ItemElse';
import { getDataType, getArgumentInformations } from '../../utils/inputs';

const AddArgumentList = (props: any) => {
  const {
    selectedTransformation,
    dataType,
    fileIndex,
    colIndex,
    type,
    dataSourceIndex,
    dataTypeIndex,
  } = props;

  const selectedStep = selectedTransformation;
  const contextHandleDataTypeChange = {
    dataType,
    fileIndex,
    colIndex,
    type,
    selectedStep: structuredClone(selectedStep),
    dataSourceIndex,
    dataTypeIndex,
  };

  return (
    <div className="column-div" data-testid="column-div-add-argument-list">
      {selectedStep?.arguments?.map((item: any, index: any) => {
        const { isList, isDict, uid } = getArgumentInformations(item);

        return (
          <Box key={uid}>
            {getDataType(item.python_datatype) === 'boolean' ? (
              <ItemBoolean
                item={item}
                index={index}
                contextHandleDataTypeChange={contextHandleDataTypeChange}
              />
            ) : isList ? (
              <ItemList
                item={item}
                index={index}
                contextHandleDataTypeChange={contextHandleDataTypeChange}
              />
            ) : isDict ? (
              <ItemDict
                item={item}
                index={index}
                contextHandleDataTypeChange={contextHandleDataTypeChange}
              />
            ) : (
              <ItemElse
                item={item}
                index={index}
                contextHandleDataTypeChange={contextHandleDataTypeChange}
              />
            )}
          </Box>
        );
      })}
    </div>
  );
};

export default AddArgumentList;
