// @ts-nocheck
import { useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { DATA_TYPE } from '../../constants/file';
import AddPreProcessingData from '../AddPreProcessingData/AddPreProcessingData';
import { PreProcessing } from '../../types/analyticsType';
import { TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';
import { toggleAccordion } from '../../slices/accordionSlice';
import { preventEnterSubmit } from '../../utils/inputs';

const DataPreProcessing = ({ analyticsData }) => {
  const [selectedDataTypes, setSelectedDataTypes] = useState<string[]>([]);
  const dispatch = useDispatch();
  const analyticsDataType = useSelector(
    (state) => state.formField.analytics[analyticsData] || [],
  );
  const expandedAccordions = useSelector(
    (state) => state.accordion.expandedAccordions,
  );
  const isAccordionExpanded = expandedAccordions.includes(analyticsData);

  const handleInputChange = (e: SelectChangeEvent, index: number) => {
    e.preventDefault();
    const dataType = e.target.value;
    const tempAnalytics = structuredClone(
      analyticsDataType || [],
    ) as PreProcessing[];
    const tempAnalyticsIndex = Object.keys(tempAnalytics).findIndex(
      (obj) => Number(obj) === index,
    );

    if (!selectedDataTypes.find((type) => type === dataType)) {
      setSelectedDataTypes([...structuredClone(selectedDataTypes), dataType]);
    }

    tempAnalytics[tempAnalyticsIndex].data_type = dataType;
    dispatch(
      updateFormField({
        path: ['analytics', analyticsData],
        value: tempAnalytics,
      }),
    );
  };

  // handle click event of the Remove button
  const handleRemoveDataTypeClick = (
    e: any,
    index: number,
    item: PreProcessing,
  ) => {
    e.preventDefault();
    const updatedValues = structuredClone(analyticsDataType || []);
    updatedValues.splice(index, 1);
    dispatch(
      updateFormField({
        path: ['analytics', analyticsData],
        value: updatedValues,
      }),
    );

    setSelectedDataTypes(
      selectedDataTypes.filter((dataType) => dataType !== item.data_type),
    );
  };

  // handle click event of the Add button
  const handleAddDataTypeClick = (e: any) => {
    e.preventDefault();
    let tempAnalytics = structuredClone(analyticsDataType || []);

    tempAnalytics = [
      ...tempAnalytics,
      {
        uid: crypto.randomUUID(),
        data_type: '',
        merchant_id_col: 'merchant_ID',
        date_col: 'trans_date',
        acquirer_col: 'acquirer',
        conditions_to_exclude: [],
      },
    ];
    dispatch(
      updateFormField({
        path: ['analytics', analyticsData],
        value: tempAnalytics,
      }),
    );
  };

  const handleValueChange = (e: any, index: number, field: string) => {
    e.preventDefault();
    const tempItem = structuredClone(analyticsDataType[index]);

    tempItem[field] = e.target.value;
    dispatch(
      updateFormField({
        path: ['analytics', analyticsData],
        value: analyticsDataType?.map(
          (item: PreProcessing, itemIndex: number) =>
            itemIndex === index ? tempItem : structuredClone(item),
        ),
      }),
    );
  };

  return (
    <MuiAccordion
      id={analyticsData}
      expanded={isAccordionExpanded}
      onChange={() => dispatch(toggleAccordion(analyticsData))}
    >
      <MuiAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ArrowForwardIosSharpIcon />}
      >
        <Typography>
          <b>Data Pre-processing</b>
        </Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        <div className="add-data-source-div">
          <p className="add-additional-reporting-table-btn">Add Data Type</p>
          <div className="btn-box acquirer-button-div">
            <button
              className="add-merchant-button"
              onClick={(e) => handleAddDataTypeClick(e)}
              // remove ---> disabled={analyticsDataType.length > 0} <--- when other data types are added in the future
              disabled={analyticsDataType?.length > 0}
            >
              <AddCircleSharpIcon />
            </button>
          </div>
        </div>
        {isAccordionExpanded &&
          analyticsDataType?.map(
            (dataItem: PreProcessing, dataItemIndex: number) => {
              return (
                <MuiAccordion
                  id={dataItem.uid}
                  expanded={expandedAccordions.includes(dataItem.uid)}
                  onChange={() => dispatch(toggleAccordion(dataItem.uid))}
                  key={dataItem.uid}
                >
                  <MuiAccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={<ArrowForwardIosSharpIcon />}
                  >
                    <Typography>
                      {dataItem.data_type
                        ? DATA_TYPE[dataItem.data_type]
                        : 'Undefined Data Type'}
                    </Typography>
                  </MuiAccordionSummary>
                  <MuiAccordionDetails>
                    <div
                      style={{
                        display: 'grid',
                        gap: '20px',
                        gridTemplateColumns: '5fr .5fr',
                      }}
                    >
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '20px',
                            flexDirection: 'column',
                          }}
                          className="data-source-type-inner-div"
                        >
                          <div className="data-source-type-div">
                            <div className="data-source-type-inner-div">
                              <FormControl required fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Data Type
                                </InputLabel>
                                <Select
                                  required
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={dataItem.data_type}
                                  label="Data Source Type"
                                  onChange={(e: SelectChangeEvent) => {
                                    handleInputChange(e, dataItemIndex);
                                  }}
                                >
                                  {Object.keys(DATA_TYPE).map(
                                    (type, typeIndex) => (
                                      <MenuItem
                                        value={type}
                                        key={typeIndex}
                                        disabled={analyticsDataType?.some(
                                          (dataItem: PreProcessing) =>
                                            // remove ---> || type !== 'transaction_data' <--- when other data types are added in the future
                                            dataItem.data_type === type ||
                                            type !== 'transaction_data',
                                        )}
                                      >
                                        {DATA_TYPE[type]}
                                      </MenuItem>
                                    ),
                                  )}
                                </Select>
                                <TextField
                                  required
                                  label="Merchant ID column"
                                  variant="standard"
                                  value={dataItem?.merchant_id_col || ''}
                                  onChange={(e) =>
                                    handleValueChange(
                                      e,
                                      dataItemIndex,
                                      'merchant_id_col',
                                    )
                                  }
                                  sx={{ margin: '10px 0 10px 0' }}
                                  onKeyDown={preventEnterSubmit}
                                />
                                <TextField
                                  required
                                  label="Date column"
                                  variant="standard"
                                  value={dataItem?.date_col || ''}
                                  onChange={(e) =>
                                    handleValueChange(
                                      e,
                                      dataItemIndex,
                                      'date_col',
                                    )
                                  }
                                  sx={{ marginBottom: '10px' }}
                                  onKeyDown={preventEnterSubmit}
                                />
                                <TextField
                                  required
                                  label="Acquirer column"
                                  variant="standard"
                                  value={dataItem?.acquirer_col || ''}
                                  onChange={(e) =>
                                    handleValueChange(
                                      e,
                                      dataItemIndex,
                                      'acquirer_col',
                                    )
                                  }
                                  sx={{ marginBottom: '10px' }}
                                  onKeyDown={preventEnterSubmit}
                                />
                              </FormControl>
                              <div>
                                <AddPreProcessingData
                                  analyticsData={analyticsData}
                                  dataItemIndex={dataItemIndex}
                                  uid={dataItem.uid}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {analyticsDataType.length !== 1 && (
                          <button
                            type="button"
                            className="remove-merchant-button"
                            onClick={(e) =>
                              handleRemoveDataTypeClick(
                                e,
                                dataItemIndex,
                                dataItem,
                              )
                            }
                          >
                            <RemoveCircleSharpIcon />
                          </button>
                        )}
                      </div>
                    </div>
                  </MuiAccordionDetails>
                </MuiAccordion>
              );
            },
          )}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default DataPreProcessing;
