import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getValidations } from '../services/validations';
import { ValidationState, Validation, Field } from '../types/validationType';

const initialState: ValidationState = {
  validations: [],
  validationsFields: [],
  loading: false,
  error: '',
};

export const fetchValidations = createAsyncThunk('validationsApi', async () => {
  return await getValidations();
});

export const validationSlice = createSlice({
  name: 'validation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchValidations.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchValidations.fulfilled, (state, action) => {
      state.loading = false;
      state.validations = action.payload as Validation[];

      state.validationsFields = (action.payload as Validation[])
        ?.map((validation: { fields: Field }) => validation.fields)
        .sort((a: Field, b: Field) => a.name.localeCompare(b.name));

      state.error = '';
    });

    builder.addCase(fetchValidations.rejected, (state, action) => {
      state.loading = false;
      state.validations = [];
      state.validationsFields = [];
      state.error = action.error.message || '';
    });
  },
});

export default validationSlice.reducer;
