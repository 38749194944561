import BuildIcon from '@mui/icons-material/Build';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CardTile from '../../components/CardTile/CardTile';
import createPipeline from '../../assets/images/GettyImages-1145612385.jpg';
import editPipeline from '../../assets/images/GettyImages-1163145791.jpg';
import statusPipeline from '../../assets/images/diagnostic.jpeg';
import config from '../../config';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <h5 style={{ textAlign: 'center' }}>
        In this web app you can create and manage data ingestion, processing and
        analytics pipelines for Customer insights and Places Insights use cases
      </h5>
      <div className="card-container">
        <CardTile
          title="Create a New Pipeline"
          desc="Build a new data pipeline using the Customer Insights solution"
          link="/create-pipeline"
          imgLink={createPipeline}
          icon={BuildIcon}
          buttonText="Get Started"
          linkType="internal"
        />
        <CardTile
          title="Check Pipeline Status"
          desc="Review the status of all pipelines in Airflow, including orchestration and errors"
          link={config.airflowUrl}
          imgLink={statusPipeline}
          icon={LeaderboardIcon}
          buttonText="Check Status"
          linkType="external"
        />
        <CardTile
          title="Edit a Pipeline"
          desc="View and edit parameters of existing pipelines (e.g.,to resolve errors or warnings)"
          link="/edit-pipeline"
          imgLink={editPipeline}
          icon={LeaderboardIcon}
          buttonText="Edit pipeline"
          linkType="internal"
        />
        <CardTile
          title="Review Data and Build Models"
          desc="Access Data Science & Engineering and Machine Learning workspaces in Databricks to review data and build or update ML models"
          link={config.databricks}
          imgLink={statusPipeline}
          icon={LeaderboardIcon}
          buttonText="ACCESS DATABRICKS"
          linkType="external"
        />
      </div>
      <h5 style={{ textAlign: 'center' }}>MFT4U Workspace Setup</h5>

      <div className="card-container">
        <CardTile
          title="Step 1"
          desc={
            'Export the current workspace (' +
            config.mft4u_workspace +
            ') JSON config through the ServiceNow ticket'
          }
          link={config.mft4usnow}
          imgLink={statusPipeline}
          icon={LeaderboardIcon}
          buttonText="ServiceNow"
          linkType="external"
        />
        <CardTile
          title="Step 2"
          desc="Upload the JSON from Step 1 and edit the MFT workspace"
          link={config.mft4unis}
          imgLink={statusPipeline}
          icon={LeaderboardIcon}
          buttonText="MFT Workspace Editor"
          linkType="external"
        />
        <CardTile
          title="MFT4U External Users Link"
          desc="Link for all non-PwC users"
          link={config.mft4ex}
          imgLink={statusPipeline}
          icon={LeaderboardIcon}
          buttonText="MFT4U EXTERNAL"
          linkType="external"
        />
        <CardTile
          title="MFT4U Internal Users Link"
          desc="Link for all PwC users"
          link={config.mft4in}
          imgLink={statusPipeline}
          icon={LeaderboardIcon}
          buttonText="MFT4U INTERNAL"
          linkType="external"
        />
      </div>
      <div className="card-container"></div>
    </div>
  );
};

export default Home;
