import { createSlice } from '@reduxjs/toolkit';
import { FileState, File } from '../types/fileType';

const initialState: FileState = {
  files: [],
};

export const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    loadFiles: (state, action: any) => {
      const data = action.payload;

      const getFilesFromDataSources = (dataSources: any = []) => {
        return dataSources
          ?.flatMap((dataSourceGroup: any) =>
            dataSourceGroup?.flatMap((dataSource: any) =>
              dataSource.data_source_type?.flatMap((dataSourceType: any) =>
                dataSourceType.file?.map((file: any) => ({
                  ...file,
                  type: dataSourceType.selected_type,
                })),
              ),
            ),
          )
          .filter(Boolean);
      };

      const dataSourceFiles = getFilesFromDataSources(data);

      state.files = structuredClone(dataSourceFiles)?.map(
        ({
          uid = '',
          file_name = '',
          transformation = [],
          validation = [],
          type = '',
          data_type = '',
          merchant_name = [],
          acquirer_name = [],
        }: File) => ({
          uid,
          file_name,
          transformation: [...transformation],
          validation: [...validation],
          type,
          data_type,
          merchant_name,
          acquirer_name,
        }),
      );
    },
  },
});

export const { loadFiles } = fileSlice.actions;

export default fileSlice.reducer;
