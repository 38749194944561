import Cookies from 'js-cookie';
import axios, { AxiosError, AxiosResponse } from 'axios';
import config from '../config';
import { CheckAuthentication } from './authentication.service';
import { getCookieWithDecryption } from '../utils/crypto';
import { addError, removeErrors } from '../slices/alertsSlice';
import { store } from '../rtk/store';
export const controller = new AbortController();

const axiosInstance = axios.create({
  baseURL: config.apiBaseUrl,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getCookieWithDecryption(
    'access_token',
  )}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (res) => {
    store.dispatch(removeErrors());
    return res;
  },
  (error: AxiosError) => {
    const { data, status, config }: any = error.response || {};
    store.dispatch(removeErrors());
    switch (status) {
      case 400:
        if (data.errors[0].message) {
          store.dispatch(
            addError({
              code: status,
              type: 'warning',
              message:
                'Validation of the pipeline configuration was unsuccessful. Please check the fields of the form and resubmit or contact support',
            }),
          );
        }
        break;

      case 401:
        console.error('unauthorised');
        Cookies.remove('access');
        Cookies.remove('access_token');
        Cookies.remove('codeVerifier');
        CheckAuthentication();
        break;

      case 404:
        console.error('/not-found');
        break;

      case 500:
        console.error('/server-error');
        break;
    }
    return Promise.reject(error);
  },
);

const responseBody = <T>(response: AxiosResponse<T>) => {
  return response.data;
};

const http = {
  get: <T>(url: string, config?: {}) =>
    axiosInstance
      .get<T>(url, { ...config, signal: controller.signal })
      .then(responseBody),
  post: <T>(url: string, body: {}, config?: {}) =>
    axiosInstance
      .post<T>(url, body, { ...config, signal: controller.signal })
      .then(responseBody),
  put: <T>(url: string, body: {}, config?: {}) =>
    axiosInstance
      .put<T>(url, body, { ...config, signal: controller.signal })
      .then(responseBody),
  patch: <T>(url: string, body: {}, config?: {}) =>
    axiosInstance
      .patch<T>(url, body, { ...config, signal: controller.signal })
      .then(responseBody),
  delete: <T>(url: string, config?: {}) =>
    axiosInstance
      .delete<T>(url, { ...config, signal: controller.signal })
      .then(responseBody),
};

export default http;
