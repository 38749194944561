// @ts-nocheck
import * as React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { AddCircleSharp } from '@mui/icons-material';
import NumericTextField from '../NumericTextField/NumericTextField';
import ButtonRemove from './ButtonRemove';
import InputSelectType from './InputSelectType';
import SubList from './SubList';
import {
  getDataType,
  handleDataTypeChange,
  delimiter,
  getArgumentInformations,
  getObjKeyName,
  getInputValue,
  getPathToArguments,
  getNewValue,
  getPlaceholder,
  preventEnterSubmit,
} from '../../utils/inputs';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';

const ItemDict = ({ item, index, contextHandleDataTypeChange }) => {
  const { label, isDictOfList, inputProps, value, specifiedDataType } =
    getArgumentInformations(item);
  const dispatch = useDispatch();
  const objKey = getObjKeyName(item);
  const pathToArgument = getPathToArguments({
    contextHandleDataTypeChange,
    addToPath: [index],
  });
  const argument = useSelector(
    (state) =>
      ['formField', ...pathToArgument].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || {},
  );

  const handleDictValueChange = (value: any, key: string, dataType: any) => {
    const input = getInputValue({ python_datatype: dataType }, value);
    const path = getPathToArguments({
      contextHandleDataTypeChange,
      addToPath: [index, objKey, key],
      index,
      key,
    });
    dispatch(updateFormField({ path, value: input }));
  };

  const handleDictKeyChange = (value: any, oldKey: string) => {
    let input = value;
    const editedArgument = structuredClone(argument);

    if (Object.keys(editedArgument[objKey]).includes(input)) {
      input += delimiter + crypto.randomUUID();
    }
    editedArgument[objKey][input] = editedArgument[objKey][oldKey] ?? '';

    editedArgument.python_datatypes[input] =
      editedArgument.python_datatypes[oldKey] ?? '';

    if (oldKey in editedArgument[objKey]) {
      delete editedArgument[objKey][oldKey];
    }

    if (oldKey in editedArgument?.python_datatypes) {
      delete editedArgument.python_datatypes[oldKey];
    }

    editedArgument.ids = editedArgument.ids.map((x, i) =>
      x.key === oldKey
        ? {
            ...x,
            key: input,
          }
        : x,
    );
    dispatch(updateFormField({ path: pathToArgument, value: editedArgument }));
  };

  const handleNewDictValue = () => {
    const editedArgument = structuredClone(argument);
    const newKey = '';

    editedArgument[objKey][newKey] = '';
    editedArgument.python_datatypes[newKey] = specifiedDataType || '';
    const newValue = { uid: crypto.randomUUID(), key: newKey };
    editedArgument.ids = Array.isArray(editedArgument.ids)
      ? structuredClone([...editedArgument.ids, newValue])
      : [newValue];
    dispatch(updateFormField({ path: pathToArgument, value: editedArgument }));
  };

  const deleteExistingKeys = () => {
    const editedArgument = structuredClone(argument);

    const myObj = editedArgument[objKey];

    for (const key in myObj) {
      if (myObj.hasOwnProperty(key) && key.includes(delimiter)) {
        delete myObj[key];
        editedArgument.ids = editedArgument.ids.filter((x, i) => x.key !== key);
      }
    }
    dispatch(updateFormField({ path: pathToArgument, value: editedArgument }));
  };

  const handleRemoveDictValue = (oldKey: string) => {
    const editedArgument = structuredClone(argument);
    editedArgument.ids = (editedArgument?.ids || []).filter(
      ({ key }) => key !== oldKey,
    );

    if (Array.isArray(editedArgument[objKey])) {
      editedArgument[objKey].splice(oldKey, 1);
    } else {
      if (oldKey in editedArgument[objKey]) {
        delete editedArgument[objKey][oldKey];
      }
    }
    dispatch(updateFormField({ path: pathToArgument, value: editedArgument }));
  };

  const handleDataTypeInDictChange = (input: any, key: number) => {
    const editedArgument = structuredClone(argument);
    const value = editedArgument[objKey][key];

    editedArgument[objKey][key] = getNewValue(
      input,
      value,
      editedArgument?.python_datatypes?.[key],
    );

    const pythonDatatypesCopy = {};
    for (const myKey of Object.keys(editedArgument[objKey])) {
      pythonDatatypesCopy[myKey] = structuredClone(
        editedArgument?.python_datatypes?.[myKey],
      );
    }

    pythonDatatypesCopy[key] = input;
    editedArgument.python_datatypes = pythonDatatypesCopy;
    dispatch(updateFormField({ path: pathToArgument, value: editedArgument }));
  };

  return (
    <div className="cmp-dict">
      <Box
        display="flex"
        gap={2}
        alignItems="center"
        mb={1}
        data-testid="bc546f73-48b7-4831-ad01-2edbb06f7692"
        className="cmp-dict__header"
      >
        <Typography
          data-testid="clq3o00ae00025s67c5ed4l3n"
          color="primary"
          ml={2}
        >
          {label}
          {item[Object.keys(item)[2]] ? <>&#xFE61;</> : <></>}
        </Typography>
        {item?.canModifyDataType && (
          <InputSelectType
            value={item.python_datatype}
            handleOnChange={(e: any) =>
              handleDataTypeChange(
                e.target.value,
                index,
                item,
                contextHandleDataTypeChange,
                argument,
                dispatch,
                updateFormField,
              )
            }
            formControlProps={{
              sx: { ml: 1 },
            }}
            selectProps={{
              sx: { minWidth: 110 },
              'data-testid': '75aaeca2-865f-414f-8f11-2e416fcd532e',
            }}
            listType="dataType"
          />
        )}
        <button
          className="add-file-button"
          onClick={handleNewDictValue}
          type="button"
          disabled={Object.keys(value).some((x) => !x)}
          data-testid="67367797-2fca-47a4-abd9-86993dac06e8"
        >
          <AddCircleSharp />{' '}
          <Box component="span" ml={0.5}>
            {isDictOfList ? 'Add key-list pair' : 'Add key-value pair'}
          </Box>
        </button>
      </Box>
      {argument?.ids?.map((argument: any, i: any) => {
        const { id, key: itemKey, uid } = argument;

        const dataType =
          specifiedDataType || getDataType(item?.python_datatypes?.[itemKey]);
        return (
          <div key={uid} className="cmp-dict__item">
            <Box
              key={id}
              data-testid="b371323e-42d6-47f3-9da2-41994e8155c4"
              display="flex"
              alignItems="center"
              gap={isDictOfList ? '8px' : '2px'}
              mb={isDictOfList ? 4 : 2}
            >
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                data-testid="3141bc4e-19db-4901-9f92-1239abaf2243"
              >
                <TextField
                  required={item[Object.keys(item)[2]]}
                  type="text"
                  label={'key ' + (i + 1)}
                  variant="standard"
                  value={
                    typeof itemKey === 'string'
                      ? itemKey?.split(delimiter)[0]
                      : itemKey
                  }
                  onChange={(e) => {
                    handleDictKeyChange(e.target.value, itemKey);
                  }}
                  onBlur={(e) => {
                    deleteExistingKeys();
                  }}
                  sx={{
                    ml: 4,
                    maxWidth: '6rem',
                  }}
                  placeholder={getPlaceholder()}
                  data-testid="0a89d64f-4007-45db-937b-ed2d72ed7e7f"
                  onKeyDown={preventEnterSubmit}
                />
              </Box>
              {['bool', 'boolean'].includes(dataType)
                ? itemKey && (
                    <InputSelectType
                      label={'value ' + (i + 1)}
                      value={value[itemKey]?.toString()}
                      handleOnChange={(e: any) =>
                        handleDictValueChange(e.target.value, itemKey, dataType)
                      }
                      selectProps={{
                        type: getDataType(item.python_datatype),
                        required: item[Object.keys(item)[2]],
                        sx: { minWidth: 110 },
                        'data-testid': '8c49271b-c28a-456d-9c99-fac67f8debd5',
                      }}
                      formControlProps={{
                        required: item[Object.keys(item)[2]],
                      }}
                      listType="dataTypeBoolean"
                    />
                  )
                : dataType !== 'list' &&
                  itemKey && (
                    <NumericTextField
                      required={item[Object.keys(item)[2]]}
                      type={getDataType(item?.python_datatypes?.[itemKey])}
                      label={'value ' + (i + 1)}
                      variant="standard"
                      value={value[itemKey]}
                      onChange={(e: any) => {
                        handleDictValueChange(
                          e.target.value,
                          itemKey,
                          item?.python_datatypes?.[itemKey],
                        );
                      }}
                      inputProps={inputProps}
                      placeholder={getPlaceholder(
                        item?.python_datatypes?.[itemKey],
                      )}
                      data-testid="06e4c104-0791-4ab3-82eb-d41f2b8dbbb5"
                      dataType={item?.python_datatypes?.[itemKey]}
                    />
                  )}
              {!specifiedDataType &&
                item?.canModifyNestedDataType &&
                itemKey && (
                  <InputSelectType
                    label={
                      dataType === 'list' ? `${i + 1}* Data type` : 'Data type'
                    }
                    value={item?.python_datatypes?.[itemKey]}
                    handleOnChange={(e: any) =>
                      handleDataTypeInDictChange(e.target.value, itemKey)
                    }
                    formControlProps={{
                      sx: { ml: 1 },
                    }}
                    selectProps={{
                      sx: { minWidth: 110 },
                      'data-testid': 'd0938139-893a-40d8-ac16-26e1403466ff',
                    }}
                    listType="dataTypeBasicWithList"
                  />
                )}
              <ButtonRemove
                label={
                  isDictOfList
                    ? 'Remove key-list pair'
                    : 'Remove key-value pair'
                }
                onRemove={() => handleRemoveDictValue(itemKey)}
                buttonProps={{
                  'data-testid': '0d3897de-541e-4f3c-8241-b9510c65cf8e',
                }}
                isDisabled={Object.keys(value).length === 1}
                className="remove-merchant-button cmp-dict__btn-remove-item"
              />
            </Box>
            <Box>
              {dataType === 'list' && itemKey && (
                <Box
                  key={id}
                  data-testid="b371323e-42d6-47f3-9da2-41994e8155c42list"
                  display="flex"
                  alignItems="center"
                  gap={isDictOfList ? '8px' : '2px'}
                  mb={isDictOfList ? 4 : 2}
                >
                  <SubList
                    subList={item[label][itemKey]}
                    inputProps={inputProps}
                    index={index}
                    item={item}
                    listIndex={itemKey}
                    contextHandleDataTypeChange={contextHandleDataTypeChange}
                  />
                </Box>
              )}
            </Box>
          </div>
        );
      })}
    </div>
  );
};

export default ItemDict;
