// @ts-nocheck
import * as React from 'react';
import TextField from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';
import { preventEnterSubmit } from '../../utils/inputs';

const NumericTextField = (props) => {
  const { dataType } = props;
  const isIntegerType = ['int', 'integer', 'number'].includes(dataType);
  const isFloatingType = ['float', 'decimal', 'double'].includes(dataType);

  const handleKeyDown = (event) => {
    const {
      key,
      target: { value },
    } = event;
    const isNegative = key === '-' && value.includes('-');
    const isDecimal = key === '.' && (value.includes('.') || isIntegerType);

    if (['e', '+'].includes(key) || isNegative || isDecimal) {
      event.preventDefault();
    }
  };

  const isNumericAllowed = (values) => {
    const { value } = values;
    const regex = isIntegerType ? /^-?\d*$/ : /^-?\d*(\.\d*)?$/;
    return regex.test(value);
  };

  if (isFloatingType || isIntegerType) {
    return (
      <NumericFormat
        {...props}
        customInput={TextField}
        isAllowed={isNumericAllowed}
        onKeyDown={handleKeyDown}
        onKeyDown={preventEnterSubmit}
      />
    );
  }

  return <TextField {...props} onKeyDown={preventEnterSubmit} />;
};

export default NumericTextField;
