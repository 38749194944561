import { createSlice } from '@reduxjs/toolkit';

const alertsSlice = createSlice({
  name: 'alerts',
  initialState: {
    alerts: [],
  },
  reducers: {
    removeErrors(state) {
      state.alerts = [];
    },
    addError(state, action) {
      const { message, type, code } = action.payload;
      const alert = {
        id: crypto.randomUUID(),
        message,
        type,
        code,
      };
      state.alerts.push(alert);
    },
  },
});

export const { removeErrors, addError } = alertsSlice.actions;
export default alertsSlice.reducer;
