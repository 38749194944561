export const prepareData = (data, skip = false) => {
  if (Array.isArray(data)) {
    data.forEach(prepareData);
  } else if (typeof data === 'object' && data !== null) {
    if (!data.uid && skip === false) {
      data.uid = crypto.randomUUID();
    }

    Object.values(data).forEach(prepareData);
  }
};

export const setCustomValidity = (field, message, myForm) => {
  if (myForm?.current?.[field] && !myForm?.current?.[field]?.[0]) {
    myForm?.current?.[field]?.setCustomValidity(message);
  }
};

function validateData(data, uidPath = []) {
  let isJsonValid = true;

  for (let dataName of Object.keys(data)) {
    if (typeof data[dataName] === 'string' && !data[dataName]) {
      isJsonValid = false;
      break;
    } else if (Array.isArray(data[dataName])) {
      if (dataName === 'arguments') {
        isJsonValid = checkArgumentsValidity(data[dataName], uidPath);
        if (data[dataName].uid && !uidPath.includes(data[dataName].uid)) {
          uidPath.push(data[dataName].uid);
        }
        if (!isJsonValid) {
          break;
        }
      } else if (
        data[dataName].length === 0 &&
        !dataName.toLowerCase().includes('transformations') &&
        !dataName.toLowerCase().includes('validations') &&
        ![
          'conditions_to_exclude',
          'condition_arguments',
          'page_sections',
          'analysis_generation',
        ].includes(dataName)
      ) {
        isJsonValid = false;
        break;
      } else {
        for (let subData of data?.[dataName] || []) {
          if (typeof subData === 'object' && subData !== null) {
            isJsonValid = validateData(subData, uidPath);
          } else if (typeof subData === 'string' && !subData) {
            if (subData.uid && !uidPath.includes(subData.uid)) {
              uidPath.push(subData.uid);
            }
            isJsonValid = false;
          }
          if (!isJsonValid) {
            if (subData.uid && !uidPath.includes(subData.uid)) {
              uidPath.push(subData.uid);
            }

            break;
          }
        }
      }
    }
    if (!isJsonValid) {
      if (data[dataName].uid && !uidPath.includes(data[dataName].uid)) {
        uidPath.push(data[dataName].uid);
      }
      break;
    }
  }

  return isJsonValid;
}

const checkArgumentsValidity = (argumentsArr, uidPath = []) => {
  let isValid = true;

  if (Array.isArray(argumentsArr)) {
    for (const argument of argumentsArr || []) {
      if (argument.is_mandatory === true) {
        const name = Object.keys(argument)[0];

        if (argument.python_datatype.toLowerCase().includes('list[dict')) {
          for (let argumentItem of argument?.[name] || []) {
            for (let dictKey of Object.keys(argumentItem)) {
              if (
                argumentItem[dictKey].key === '' ||
                argumentItem[dictKey].value === ''
              ) {
                isValid = false;
                uidPath.push(argument.uid);
                return isValid;
              }
            }
          }
        } else if (argument.python_datatype.toLowerCase().includes('list')) {
          for (let argumentItem of argument?.[name] || []) {
            if (argumentItem === '') {
              isValid = false;
              uidPath.push(argument.uid);

              return isValid;
            }
          }
        } else if (argument.python_datatype.toLowerCase().includes('dict')) {
          for (let dictKey of Object.keys(argument[name])) {
            if (dictKey === '' || argument[name][dictKey] === '') {
              isValid = false;
              uidPath.push(argument.uid);

              return isValid;
            }
          }
        } else {
          if (argument[name] === '') {
            isValid = false;
            uidPath.push(argument.uid);

            return isValid;
          }
        }
      }
    }
  }

  return isValid;
};

export const validateStep = ({
  activeStep,
  formField,
  expandedAccordions,
  setExpandedAccordions,
  dispatch,
  myForm,
}) => {
  let uidPath = [];
  let isJsonValid = true;
  let dataSourceName = '';
  if (activeStep === 3) {
    dataSourceName = 'merchant_data_sources';
  } else if (activeStep === 4) {
    dataSourceName = 'client_data_sources';
    if (!formField.engagement_details.data_providers.includes('client')) {
      return [isJsonValid, uidPath];
    }
  } else if (activeStep === 5) {
    dataSourceName = 'engagement_team_data_sources';
    if (!formField.engagement_details.data_providers.includes('engagement_team')) {
      return [isJsonValid, uidPath];
    }
  } else if (activeStep === 6) {
    dataSourceName = 'acquirer_data_sources';
    if (!formField.engagement_details.data_providers.includes('acquirer')) {
      return [isJsonValid, uidPath];
    }
  } else if (activeStep === 7) {
    dataSourceName = 'third_party_data_sources';
  } else if (activeStep === 8) {
    dataSourceName = 'final_data';
  } else if (activeStep === 9) {
    dataSourceName = 'analytics';
  }
  if (activeStep === 9) {
    for (let data of formField?.[dataSourceName]?.['analysis_preprocess'] ||
      []) {
      uidPath = ['analysis_preprocess', data.uid];
      isJsonValid = validateData(data, uidPath);

      if (!isJsonValid) {
        break;
      }
    }

    for (let data of formField?.[dataSourceName]?.['analysis_output'] || []) {
      uidPath = ['analysis_output', data.uid];
      isJsonValid = validateData(data, uidPath);
      if (!isJsonValid) {
        break;
      }
    }

    for (let data of formField?.[dataSourceName]?.['other_output'] || []) {
      uidPath = ['other_output', data.uid];
      isJsonValid = validateData(data, uidPath);
      if (!isJsonValid) {
        break;
      }
    }
  }
  if ([3, 4, 5, 6, 7, 8].includes(activeStep)) {
    for (let formFieldData of formField?.[dataSourceName] || []) {
      uidPath = [];
      uidPath.push(formFieldData.uid);

      if (
        dataSourceName === 'acquirer_data_sources' && formField?.engagement_details?.data_providers?.includes('acquirer') &&
        (!formFieldData.acquirer_name || !formFieldData.merchants.length)
      ) {
        isJsonValid = false;
        break;
      }

      if (dataSourceName === 'final_data') {
        if (!formFieldData.name) {
          isJsonValid = false;
          break;
        }

        for (const transformation of formFieldData?.final_transformations ||
          []) {
          const [isTransformationValid, uidTransformationPath] =
            checkTransformationsAndValidationsValidity(
              transformation,
              'transformation',
            );

          if (!isTransformationValid) {
            isJsonValid = false;
            uidPath = [...uidPath, ...uidTransformationPath];
            break;
          }
        }

        for (const validation of formFieldData?.final_validations || []) {
          const [isValidationValid, uidValidationPath] =
            checkTransformationsAndValidationsValidity(
              validation,
              'validation',
            );

          if (!isValidationValid) {
            isJsonValid = false;
            uidPath = [...uidPath, ...uidValidationPath];
            break;
          }
        }
        if (!isJsonValid) {
          break;
        }
      }

      for (let sourceType of formFieldData?.data_source_type || []) {
        uidPath.push(sourceType.uid);

        if (dataSourceName === 'acquirer_data_sources') {
          uidPath.push(`data_source_type-${formFieldData.uid}`);
        }
        if (!sourceType.selected_type) {
          isJsonValid = false;

          break;
        } else if (sourceType.selected_type === 'azure') {
          if (!sourceType.url || !sourceType.sas_token) {
            isJsonValid = false;
            break;
          }
        } else if (sourceType.selected_type === 'MFT4U') {
          if (!sourceType.email.length) {
            isJsonValid = false;
            break;
          }
        } else if (sourceType.selected_type === 'Api') {
          if (
            !sourceType.url ||
            !sourceType.key ||
            !sourceType.user_name ||
            !sourceType.password ||
            !sourceType.ingestion_start_date ||
            !sourceType.ingestion_end_date
          ) {
            isJsonValid = false;
            break;
          }
        }

        if (Array.isArray(sourceType?.file)) {
          for (let file of sourceType?.file || []) {
            const [isFileValid, filePath] = checkFileValidity(
              file,
              sourceType,
              myForm,
            );

            if (!isFileValid) {
              isJsonValid = false;
              uidPath = [...uidPath, ...filePath];
              break;
            }
          }
        }
      }

      if (!isJsonValid) {
        break;
      }
    }
  }

  let uidPathToAdd = uidPath.filter(
    (item) => item !== undefined && !expandedAccordions.includes(item),
  );

  if (!isJsonValid && uidPathToAdd.length > 0) {
    dispatch(setExpandedAccordions([...expandedAccordions, ...uidPathToAdd]));
  }

  return [isJsonValid, uidPathToAdd];
};

const checkTransformationsAndValidationsValidity = (item, type) => {
  let isValid = true;
  let uidPath = [];

  if (!item.name) {
    isValid = false;
    uidPath.push(item.uid);

    return [isValid, uidPath];
  }

  isValid = checkArgumentsValidity(item.arguments, uidPath);
  if (!isValid) {
    uidPath.push(item.uid);
  }

  return [isValid, uidPath];
};

const checkFileValidity = (file, sourceType, myForm) => {
  let isValid = true;
  let uidPath = [];
  if (
    !file.file_name ||
    !file.file_type ||
    !file.data_type ||
    !file.priority ||
    !file.col_name.length
  ) {
    isValid = false;
    uidPath.push(file.uid);
    return [isValid, uidPath];
  }

  if (file.data_type === 'lookup_tables') {
    setCustomValidity('lookup_tables', 'Please fill in this field', myForm);
  }

  if (file.data_type === 'other_data') {
    setCustomValidity('other_data', 'Please fill in this field', myForm);
  }

  if (
    file.data_type === 'transaction_data' &&
    (!file.acquirer_name.length || !file?.merchant_name?.length)
  ) {
    isValid = false;
    uidPath.push(file.uid);
    return [isValid, uidPath];
  }

  if (sourceType.selected_type === 'MFT4U' && !file?.emails?.length) {
    isValid = false;
    uidPath.push(file.uid);
    return [isValid, uidPath];
  }

  for (const transformation of file?.transformation || []) {
    const [isTransformationValid, uidTransformationPath] =
      checkTransformationsAndValidationsValidity(
        transformation,
        'transformation',
      );

    if (!isTransformationValid) {
      isValid = false;
      uidPath = [
        file.uid,
        `transformations-${file.uid}`,
        ...uidTransformationPath,
      ];
      return [isValid, uidPath];
    }
  }

  for (const validation of file?.validation || []) {
    const [isValidationValid, uidValidationPath] =
      checkTransformationsAndValidationsValidity(validation, 'validation');

    if (!isValidationValid) {
      isValid = false;
      uidPath = [file.uid, `validations-${file.uid}`, ...uidValidationPath];
      return [isValid, uidPath];
    }
  }

  return [isValid, uidPath];
};
