// @ts-nocheck
import { useState, useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';

import CopyFile from '../CopyFile/CopyFile';
import { IconButton, Tooltip, FormHelperText } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddValidation from '../../components/AddValidation/AddValidation';
import { File } from '../../types/fileType';
import { Field } from '../../types/validationType';
import { VALIDATIONS } from '../../constants/file';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';

import {
  setExpandedAccordions,
  toggleAccordion,
} from '../../slices/accordionSlice';
import { TEXT } from '../../constants/text';

const ValidationsAccordion = ({
  pathToSourceType,
  fileIndex,
  dataType,
  dataSourceIndex,
  dataTypeIndex,
  fileItem,
}: any) => {
  const dispatch = useDispatch();
  const pathToValdation = [
    ...pathToSourceType,
    'file',
    fileIndex,
    'validation',
  ];
  const formFieldValidation = useSelector(
    (state) =>
      ['formField', ...pathToValdation].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || {},
  );
  const expandedAccordions = useSelector(
    (state: any) => state.accordion.expandedAccordions,
  );

  const accordionId = `validations-${fileItem.uid}`;
  const isAccordionExpanded = expandedAccordions.includes(accordionId);
  useEffect(() => {
    let shouldAddId = false;
    if (formFieldValidation) {
      const updatedValidations = formFieldValidation.map((item) => {
        if (!item.uid) {
          shouldAddId = true;
          return { ...structuredClone(item), uid: crypto.randomUUID() };
        }
        return structuredClone(item);
      });

      if (shouldAddId) {
        dispatch(
          updateFormField({
            path: [
              dataType,
              dataSourceIndex,
              'data_source_type',
              dataTypeIndex,
              'file',
              fileIndex,
              'validation',
            ],
            value: updatedValidations,
          }),
        );
      }
    }
  }, [formFieldValidation]);

  const [hoveredValidationStep, setValidationHoveredStep] = useState(-1);
  const [selectedFileValidationIndex, setSelectedFileValidationIndex] =
    useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>({});
  const filesSelector = useSelector((state: RootState) => state.files.files);

  const AddValidationFile = (
    e: any,
    index: number = formFieldValidation.length,
  ) => {
    e.preventDefault();
    const editedFormFieldValidation = structuredClone(formFieldValidation);
    editedFormFieldValidation.splice(index, 0, {
      uid: crypto.randomUUID(),
      name: '',
      description: '',
      arguments: [],
    });
    dispatch(
      updateFormField({
        path: pathToValdation,
        value: editedFormFieldValidation,
      }),
    );
  };

  const copyValidationFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (selectedFile.file_name != null) {
      const editedSelectedFileValidation = structuredClone(
        selectedFile?.validation,
      ).map((item) => {
        return {
          ...item,
          uid: crypto.randomUUID(),
        };
      });
      const editedFormFieldValidation = structuredClone([
        ...formFieldValidation,
        ...editedSelectedFileValidation,
      ]);

      dispatch(
        updateFormField({
          path: pathToValdation,
          value: editedFormFieldValidation,
        }),
      );
      enqueueSnackbar(
        `Validations copied from ${selectedFile.file_name} successfully`,
        {
          variant: 'success',
        },
      );
    }
    setSelectedFileValidationIndex('');
    setSelectedFile({});
  };

  const handleSelectValidationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const tempSelectedFileIndex = Number(
      Object.keys(filesSelector).find(
        (index) => index === event.target.value.toString(),
      ),
    );

    setSelectedFileValidationIndex(event.target.value);
    setSelectedFile(filesSelector[tempSelectedFileIndex]);
  };

  const collapseAllValidations = () => {
    const newArray = formFieldValidation.map((validation) => validation.uid);
    const filteredAccordions = expandedAccordions.filter(
      (accordion) => !newArray.includes(accordion),
    );

    dispatch(setExpandedAccordions(filteredAccordions));
  };

  const expandAllValidations = () => {
    const newArray = [];

    formFieldValidation.forEach((validation) => {
      if (!expandedAccordions.includes(validation.uid)) {
        newArray.push(validation.uid);
      }
    });
    dispatch(setExpandedAccordions([...expandedAccordions, ...newArray]));
  };

  const getValidationStepIndex = (index) => {
    setValidationHoveredStep(index);
  };

  return (
    <MuiAccordion
      id={accordionId}
      expanded={isAccordionExpanded}
      onChange={() => dispatch(toggleAccordion(accordionId))}
    >
      <MuiAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ArrowForwardIosSharpIcon />}
      >
        <Typography>Validations</Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        {/* add validation step at the beginning */}
        <div className="add-file-info-text">
          <FormHelperText>{TEXT.message_above_add_validation}</FormHelperText>
        </div>
        <div className="add-file-div">
          <div className="add-button-div">
            <p className="bold">Add Validations</p>
            <button
              className="add-file-button"
              onClick={(e) => AddValidationFile(e, 0)}
              type="button"
              data-testid="980b2bfe-8ca4-4b6c-9fec-88c9e5eecd9d"
            >
              <AddCircleSharpIcon />
            </button>
          </div>
          <Tooltip title="Collapse All">
            <IconButton onClick={collapseAllValidations}>
              <UnfoldLessIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Expand All">
            <IconButton onClick={expandAllValidations}>
              <UnfoldMoreIcon />
            </IconButton>
          </Tooltip>
          {/* copy validation steps from another file */}
          <CopyFile
            selectedValue={selectedFileValidationIndex}
            onChangeSelect={handleSelectValidationChange}
            selectItems={filesSelector}
            onSubmitButton={copyValidationFiles}
            isDisabledSubmit={selectedFile.file_name === undefined}
          />
        </div>
        <div>
          {isAccordionExpanded && (
            <div className="col-div">
              {formFieldValidation
                ? formFieldValidation.map(
                    (validationItem: Field, validationIndex: number) => {
                      return (
                        <div
                          key={validationItem.uid}
                          // get validation step index on hover
                          onMouseEnter={() =>
                            getValidationStepIndex(validationIndex)
                          }
                          onMouseLeave={() => getValidationStepIndex(-1)}
                        >
                          <MuiAccordion
                            id={validationItem.uid}
                            key={validationItem.uid}
                            onChange={() =>
                              dispatch(toggleAccordion(validationItem.uid))
                            }
                            expanded={expandedAccordions.includes(
                              validationItem.uid,
                            )}
                          >
                            <MuiAccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                              expandIcon={<ArrowForwardIosSharpIcon />}
                            >
                              <Typography>
                                {VALIDATIONS[validationItem.name] ||
                                  validationItem.name
                                    .replace(/_/g, ' ')
                                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                      letter.toUpperCase(),
                                    )}
                              </Typography>
                            </MuiAccordionSummary>
                            <MuiAccordionDetails>
                              <AddValidation
                                dataType={dataType}
                                colItem={validationItem}
                                colIndex={validationIndex}
                                pathToSourceType={pathToSourceType}
                                dataSourceIndex={dataSourceIndex}
                                dataTypeIndex={dataTypeIndex}
                                fileIndex={fileIndex}
                              />
                            </MuiAccordionDetails>
                          </MuiAccordion>
                          {/* Add validation step at index */}
                          <div
                            className={`step-add-button ${
                              validationIndex !==
                                formFieldValidation.length - 1 &&
                              hoveredValidationStep === validationIndex
                                ? ''
                                : 'hide-step-add-button'
                            } ${
                              validationIndex === formFieldValidation.length - 1
                                ? 'last-step-add-button'
                                : ''
                            }`}
                          >
                            <button
                              className="add-file-button"
                              onClick={(e) =>
                                AddValidationFile(e, validationIndex + 1)
                              }
                              type="button"
                              data-testid="9e3710d2-1459-4c1b-b393-c2eed8a27b09"
                            >
                              <AddCircleSharpIcon />
                            </button>
                          </div>
                        </div>
                      );
                    },
                  )
                : enqueueSnackbar('Add a step or select a file to copy from', {
                    variant: 'warning',
                  })}
            </div>
          )}
        </div>
        {/* add validation step at the end */}
        {formFieldValidation?.length ? (
          <div className="add-file-div">
            <button
              className="add-file-button"
              onClick={(e) => AddValidationFile(e, formFieldValidation.length)}
              type="button"
              data-testid="f915ba77-2c6d-4599-80bd-f05ec3e3f6ee"
            >
              <AddCircleSharpIcon />
            </button>
          </div>
        ) : (
          ''
        )}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default ValidationsAccordion;
