import { createSlice } from '@reduxjs/toolkit';
import { DataTypeState } from '../types/dataTypes';

const initialState: DataTypeState = {
  data_types: [
    { value: 'transaction_data', name: 'Transaction Data' },
    { value: 'other_data', name: 'Other Data' },
    { value: 'lookup_tables', name: 'Look Up Tables' },
  ],
};

export const dataTypesSlice = createSlice({
  name: 'dataTypes',
  initialState,
  reducers: {},
});

export default dataTypesSlice.reducer;
