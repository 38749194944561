import { Link } from 'react-router-dom';
import './Error.css';

const Error = () => {
  return (
    <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
      Page Not Found
      <p style={{ textAlign: 'center' }}>
        <Link to="/">Go to Home </Link>
      </p>
    </div>
  );
};

export default Error;
