// @ts-nocheck
import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import { AdditionalTable } from '../../types/analyticsType';
import ReportPage from '../ReportPage/ReportPage';
import { useSelector, useDispatch } from 'react-redux';
import { toggleAccordion } from '../../slices/accordionSlice';
import { updateFormField } from '../../slices/formFieldSlice';
import './AdditionalReportingTable.css';

const AdditionalReportingTable = ({ analyticsData }) => {
  const dispatch = useDispatch();
  const formFieldAnalyticsData = useSelector(
    (state) => state.formField.analytics[analyticsData] || [],
  );
  const expandedAccordions = useSelector(
    (state) => state.accordion.expandedAccordions,
  );
  const isAccordionExpanded = expandedAccordions.includes(analyticsData);
  // handle click event of the Add button
  const handleAddAdditionalReportingTableClick = (e: any) => {
    e.preventDefault();
    let tempAdditionalReportingTables = structuredClone(
      formFieldAnalyticsData || [],
    );

    tempAdditionalReportingTables = [
      ...tempAdditionalReportingTables,
      {
        uid: crypto.randomUUID(),
        output_name: '',
        analysis_generation: [],
      },
    ];
    dispatch(
      updateFormField({
        path: ['analytics', analyticsData],
        value: tempAdditionalReportingTables,
      }),
    );
  };

  return (
    <MuiAccordion
      id={analyticsData}
      expanded={isAccordionExpanded}
      onChange={() => dispatch(toggleAccordion(analyticsData))}
    >
      <MuiAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ArrowForwardIosSharpIcon />}
      >
        <Typography>
          <b>Additional Reporting Table</b> (with custom data schemas)
        </Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        <div
          style={{
            display: 'grid',
            gap: '20px',
            gridTemplateColumns: '5fr .5fr',
          }}
        >
          <div>
            <div className="data-source-type-inner-div">
              <div className="add-data-source-div">
                <p className="add-additional-reporting-table-btn">
                  Add Additional Reporting Table
                </p>
                <div className="btn-box acquirer-button-div">
                  <button
                    className="add-merchant-button"
                    onClick={(e) => handleAddAdditionalReportingTableClick(e)}
                  >
                    <AddCircleSharpIcon />
                  </button>
                </div>
              </div>
              {isAccordionExpanded &&
                formFieldAnalyticsData?.map(
                  (page: AdditionalTable, pageIndex: number) => {
                    return (
                      <div key={page.uid}>
                        <ReportPage
                          analyticsData={analyticsData}
                          page={page}
                          pageIndex={pageIndex}
                          additional={true}
                        />
                      </div>
                    );
                  },
                )}
            </div>
          </div>
        </div>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default AdditionalReportingTable;
