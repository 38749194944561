import { configureStore } from '@reduxjs/toolkit';
import transformationReducer from '../slices/transformationSlice';
import validationReducer from '../slices/validationSlice';
import blobReducer from '../slices/blobSlice';
import fileReducer from '../slices/fileSlice';
import analyticsReducer from '../slices/analyticsSlice';
import dataTypesReducer from '../slices/dataTypesSlice';
import otherDataTypesReducer from '../slices/otherDataTypesSlice';
import lookupTablesReducer from '../slices/lookupTablesSlice';
import formFieldReducer from '../slices/formFieldSlice';
import accordiondReducer from '../slices/accordionSlice';
import alertsReducer from '../slices/alertsSlice';

export const store = configureStore({
  reducer: {
    transformations: transformationReducer,
    validations: validationReducer,
    blobs: blobReducer,
    files: fileReducer,
    analytics: analyticsReducer,
    dataTypes: dataTypesReducer,
    otherDataTypes: otherDataTypesReducer,
    lookupTables: lookupTablesReducer,
    formField: formFieldReducer,
    accordion: accordiondReducer,
    alerts: alertsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
