import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAnalytics } from '../services/analytics';
import { ReportingKPI, AnalyticsState, Field } from '../types/analyticsType';

const initialState: AnalyticsState = {
  analytics: [],
  analyticsFields: [],
  loading: false,
  error: '',
};

export const fetchAnalytics = createAsyncThunk('analyticsAPI', async () => {
  const analytics = await getAnalytics();
  return analytics;
});

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAnalytics.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchAnalytics.fulfilled, (state, action) => {
      state.loading = false;
      state.analytics = action.payload as ReportingKPI[];

      state.analyticsFields = (action.payload as ReportingKPI[])
        ?.map((analyticsField: { fields: Field }) => analyticsField.fields)
        .sort((a: Field, b: Field) => a.name.localeCompare(b.name));

      state.error = '';
    });

    builder.addCase(fetchAnalytics.rejected, (state, action) => {
      state.loading = false;
      state.analytics = [];
      state.analyticsFields = [];
      state.error = action.error.message || '';
    });
  },
});

export default analyticsSlice.reducer;
