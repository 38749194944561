// @ts-nocheck
import React from 'react';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddConditionArgumentList from '../AddConditionArgumentList/AddConditionArgumentList';
import { useSelector, useDispatch } from 'react-redux';
import { toggleAccordion } from '../../slices/accordionSlice';
import './AddCondition.css';

const AddCondition = ({
  analyticsData,
  colIndex,
  dataItemIndex,
  condition,
  conditionIndex,
  uid,
}: any) => {
  const dispatch = useDispatch();
  const expandedAccordions = useSelector(
    (state) => state.accordion.expandedAccordions,
  );
  const isAccordionExpanded = expandedAccordions.includes(uid);

  return (
    <MuiAccordion
      id={uid}
      key={uid}
      sx={{ width: '100%' }}
      expanded={isAccordionExpanded}
      onChange={() => dispatch(toggleAccordion(uid))}
    >
      <MuiAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ArrowForwardIosSharpIcon />}
      >
        <Typography>{`Condition ${conditionIndex + 1}`}</Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        <div>
          <div>
            <AddConditionArgumentList
              analyticsData={analyticsData}
              dataItemIndex={dataItemIndex}
              colIndex={colIndex}
              condition={condition}
              conditionIndex={conditionIndex}
            />
          </div>
        </div>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default AddCondition;
