import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBlobs } from '../services/blobs';
import { Blob, BlobState } from '../types/blobType';

const initialState: BlobState = {
  blobs: [],
  loading: false,
  error: '',
  status: false,
};

export const fetchBlobs = createAsyncThunk('blobsApi', async () => {
  const blobs = await getBlobs();
  return blobs;
});

export const blobSlice = createSlice({
  name: 'blobs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBlobs.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchBlobs.fulfilled, (state, action) => {
      state.loading = false;
      state.blobs = action.payload as Blob[];
      state.error = '';
      state.status = action.meta.requestStatus === 'fulfilled' ? true : false;
    });

    builder.addCase(fetchBlobs.rejected, (state, action) => {
      state.loading = false;
      state.blobs = [];
      state.error = action.error.message || '';
      state.status = false;
    });
  },
});

export default blobSlice.reducer;
