// @ts-nocheck
import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ApiDataSource from '../../components/ApiDataSource/ApiDataSource';
import AzureDataSource from '../../components/AzureDataSource/AzureDataSource';
import MftDataSource from '../../components/MftDataSource/MftDataSource';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateMerchantDataSources,
  updateFormField,
} from '../../slices/formFieldSlice';
import {
  setExpandedAccordions,
  toggleAccordion,
} from '../../slices/accordionSlice';

import './AddDataSource.css';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0.5px solid #7d7d7d`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : '#dedede',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const AddDataSource = () => {
  const dispatch = useDispatch();
  const merchantDataSources = useSelector(
    (state) => state.formField.merchant_data_sources,
  );
  const expandedAccordions = useSelector(
    (state) => state.accordion.expandedAccordions,
  );

  const [expanded, setExpanded] = useState(merchantDataSources[0].uid);

  useEffect(() => {
    if (!expandedAccordions.includes(merchantDataSources[0].uid)) {
      dispatch(setExpandedAccordions([...expandedAccordions, expanded]));
      setExpanded(false);
    }
  }, []);

  const handleInputChange = (
    e: SelectChangeEvent,
    index: any,
    item: any,
    j: any,
  ) => {
    e.preventDefault();
    const updatedValues = structuredClone(merchantDataSources);
    updatedValues[index].data_source_type[j].selected_type = e.target.value;
    if (e.target.value === 'Api') {
      updatedValues[index].data_source_type[j].url = '';
      updatedValues[index].data_source_type[j].user_name = '';
      updatedValues[index].data_source_type[j].key = '';
      updatedValues[index].data_source_type[j].password = '';
      updatedValues[index].data_source_type[j].ingestion_start_date = '';
      updatedValues[index].data_source_type[j].ingestion_end_date = '';
    } else if (e.target.value === 'azure') {
      updatedValues[index].data_source_type[j].url = '';
      updatedValues[index].data_source_type[j].sas_token = '';
    } else {
      updatedValues[index].data_source_type[j].email = [];
    }
    updatedValues[index].data_source_type[j].file = [];
    updatedValues[index].data_source_type[j].file_count =
      updatedValues[index].data_source_type[j].file.length;

    dispatch(updateMerchantDataSources({ data: updatedValues }));
  };

  const handleAddDataSourceClick = (e: any, i: any, item: any) => {
    e.preventDefault();
    const updatedValues = structuredClone(merchantDataSources);
    updatedValues[i].data_source_type = [
      ...updatedValues[i].data_source_type,
      { uid: crypto.randomUUID(), selected_type: '' },
    ];
    dispatch(updateMerchantDataSources({ data: updatedValues }));
  };

  const handleRemoveDataSourceClick = (e: any, i: any, j: any, item: any) => {
    e.preventDefault();

    const updatedValues = structuredClone(merchantDataSources);
    updatedValues[i].data_source_type.splice(j, 1);
    dispatch(updateMerchantDataSources({ data: updatedValues }));
  };

  return (
    <>
      {merchantDataSources?.map((item: any, i: any) => {
        const itemName = item.merchant_name
          ? item.merchant_name
          : item.clientName;
        const isAccordionExpanded =
          expandedAccordions.includes(item.uid) || expanded === item.uid;
        return (
          <Accordion
            id={item.uid}
            expanded={isAccordionExpanded}
            onChange={() => dispatch(toggleAccordion(item.uid))}
            key={item.uid}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography>{itemName}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="data-source-div">
                <div className="add-data-source-div">
                  <p style={{ fontWeight: 'bold' }}>Add Data Source</p>
                  <button
                    className="add-data-source-button"
                    onClick={(e) => handleAddDataSourceClick(e, i, item)}
                    type="button"
                  >
                    <AddCircleSharpIcon />
                  </button>
                </div>
                {isAccordionExpanded &&
                  item?.data_source_type.map((sourceType: any, j: any) => {
                    return (
                      <div
                        className="data-source-type-div"
                        key={sourceType.uid}
                      >
                        <div className="data-source-type-inner-div">
                          <FormControl required fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Data Source Type
                            </InputLabel>
                            <Select
                              required
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={sourceType.selected_type}
                              label="Data Source Type"
                              onChange={(e: SelectChangeEvent) =>
                                handleInputChange(e, i, item, j)
                              }
                            >
                              <MenuItem value="azure">Microsoft Azure</MenuItem>
                              <MenuItem value="MFT4U">MFT4U</MenuItem>
                            </Select>
                          </FormControl>
                          {sourceType.selected_type === 'Api' && (
                            <ApiDataSource
                              dataType="merchant_data_sources"
                              dataTypeIndex={j}
                              dataSourceIndex={i}
                            />
                          )}
                          {sourceType.selected_type === 'azure' && (
                            <AzureDataSource
                              dataType="merchant_data_sources"
                              dataTypeIndex={j}
                              dataSourceIndex={i}
                            />
                          )}
                          {sourceType.selected_type === 'MFT4U' && (
                            <MftDataSource
                              dataType="merchant_data_sources"
                              dataTypeIndex={j}
                              dataSourceIndex={i}
                            />
                          )}
                        </div>
                        <div>
                          <button
                            className="remove-data-source-button"
                            onClick={(e) =>
                              handleRemoveDataSourceClick(e, i, j, item)
                            }
                          >
                            <RemoveCircleSharpIcon />
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default AddDataSource;
