// @ts-nocheck
export const getDataType = (fieldType: string) => {
  const typeMapping: { [key: string]: string } = {
    bool: 'boolean',
    boolean: 'boolean',
    float: 'number',
    number: 'number',
    decimal: 'number',
    double: 'number',
    int: 'number',
    integer: 'number',
    string: 'text',
    str: 'text',
    list: 'list',
    dict: 'dict',
  };

  const x = fieldType?.toLowerCase() || '';
  return typeMapping[x] || 'text';
};

export const getPlaceholder = (input: string) => {
  const typeMapping: { [key: string]: string } = {
    int: 'Integer',
    integer: 'Integer',
    number: 'Integer',
    double: 'Decimal',
    float: 'Decimal',
    decimal: 'Decimal',
    bool: 'Boolean',
    boolean: 'Boolean',
  };

  return typeMapping[input] || 'Text';
};

const isObject = (input) =>
  typeof input === 'object' && !Array.isArray(input) && input !== null;

export const isValidBoolean = (value: boolean | string): boolean => {
  return (
    typeof ['bool', 'boolean'].includes(value) ||
    (typeof value === 'string' && (value === 'true' || value === 'false'))
  );
};

export const isValidNumber = (input: string | number): boolean => {
  if (typeof input === 'number') {
    return !isNaN(input) && isFinite(input);
  } else if (typeof input === 'string') {
    const numberPattern = /^-?[0-9]+(\.[0-9]+)?$/;
    return numberPattern.test(input);
  }
  return false;
};

export const toBoolean = (value: string | boolean): boolean => {
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true';
  }
  return Boolean(value);
};

export const getObjKeyName = (fieldName) => Object.keys(fieldName)[0];
export const getInputValue = (fieldName, value) =>
  isNumeric(fieldName, value)
    ? Number(value)
    : isBoolean(fieldName, value)
      ? toBoolean(value)
      : value;

export const booleanDTs = [
  'boolean',
  'list[boolean]',
  'dict[boolean]',
  'bool',
  'list[bool]',
  'dict[bool]',
];
export const numericDTs = [
  'int',
  'list[int]',
  'dict[int]',
  'integer',
  'number',
  'list[integer]',
  'dict[integer]',
];
export const decimalDTs = [
  'dict[float]',
  'float',
  'list[float]',
  'dict[double]',
  'double',
  'list[double]',
];
export const delimiter = '^^^^^^^^^^^^';
export const isBoolean = (fieldName, e) =>
  (booleanDTs.includes(fieldName.type) ||
    booleanDTs.includes(fieldName.python_datatype)) &&
  isValidBoolean(e);
export const isNumeric = (fieldName, e) =>
  (numericDTs.includes(fieldName.type) ||
    numericDTs.includes(fieldName.python_datatype) ||
    decimalDTs.includes(fieldName.type) ||
    decimalDTs.includes(fieldName.python_datatype)) &&
  isValidNumber(e);

export const getPathToArguments = ({
  contextHandleDataTypeChange,
  addToPath = [],
}) => {
  const { dataType, colIndex } = contextHandleDataTypeChange;
  let path = [];

  if (['final_transformation', 'final_validation'].includes(dataType)) {
    const { finalItemName } = contextHandleDataTypeChange;
    path = [dataType, finalItemName, colIndex, 'arguments'];
  } else if (
    [
      'merchant_data_sources',
      'client_data_sources',
      'engagement_team_data_sources',
      'acquirer_data_sources',
      'third_party_data_sources',
    ].includes(dataType)
  ) {
    const { fileIndex, type, dataSourceIndex, dataTypeIndex } =
      contextHandleDataTypeChange;
    const isTypeTransforamtion = type === 'transformation';
    path = [
      dataType,
      dataSourceIndex,
      'data_source_type',
      dataTypeIndex,
      'file',
      fileIndex,
      isTypeTransforamtion ? 'transformation' : 'validation',
      colIndex,
      'arguments',
    ];
  } else if (dataType === 'analytics') {
    const { pageIndex, analyticsData, pageSectionIndex, additional } =
      contextHandleDataTypeChange;
    if (additional) {
      path = [
        dataType,
        analyticsData,
        pageIndex,
        'analysis_generation',
        colIndex,
        'arguments',
      ];
    } else {
      path = [
        dataType,
        analyticsData,
        pageIndex,
        'page_sections',
        pageSectionIndex,
        'analysis_generation',
        colIndex,
        'arguments',
      ];
    }
  }

  return [...path, ...addToPath];
};

export const getNewValue = (newDataType, value) => {
  const isValidValue =
    value !== '' && value != null && typeof value !== 'object';
  const defaultVal = '';
  if (newDataType === 'list') {
    return {
      [crypto.randomUUID()]: { value: defaultVal, python_datatype: 'str' },
    };
  } else if (newDataType === 'str') {
    return isValidValue ? value.toString() : defaultVal;
  } else if (newDataType === 'double') {
    return isValidValue && !isNaN(Number.parseFloat(value))
      ? Number.parseFloat(value)
      : defaultVal;
  } else if (newDataType === 'int') {
    return isValidValue && !isNaN(Number.parseInt(value))
      ? Number.parseInt(value)
      : defaultVal;
  }

  return defaultVal;
};

export const getValueOrDefault = (value, defaultVal = '') =>
  value !== undefined ? value : defaultVal;
export const getSpecifiedDataType = (argument) => {
  let specifiedDataType;
  const match = argument?.python_datatype?.match(/\[(.*?)\]/);
  if (match) {
    const specifiedDataTypeArr = match[1].split(',');
    if (specifiedDataTypeArr.length) {
      const typeMapping: { [key: string]: string } = {
        bool: 'bool',
        boolean: 'bool',
        float: 'double',
        number: 'int',
        decimal: 'double',
        double: 'double',
        int: 'int',
        integer: 'int',
        string: 'str',
        str: 'str',
        list: 'list',
        dict: 'dict',
      };

      const x =
        specifiedDataTypeArr[specifiedDataTypeArr.length - 1]
          .trim()
          ?.toLowerCase() || '';
      specifiedDataType = typeMapping[x] || '';
    }
  }
  return specifiedDataType;
};
export const getArgumentInformations = (item) => {
  const itemType = getValueOrDefault(item?.type?.toLowerCase());
  const itemDataType = getValueOrDefault(item?.python_datatype?.toLowerCase());
  const label = getValueOrDefault(Object.keys(item)[0]);
  const isList =
    itemDataType.indexOf('list') === 0 || itemType.indexOf('list') === 0;
  const isDict =
    itemDataType.indexOf('dict') === 0 || itemType.indexOf('dict') === 0;
  const isDictOfList =
    ['dict[list]', 'dict[str,list]'].includes(itemDataType) ||
    ['dict[list]', 'dict[str,list]'].includes(itemType);
  const isListOfDict =
    ['list[dict]'].includes(itemDataType) || ['list[dict]'].includes(itemType);
  const isInt = numericDTs.includes(itemDataType);
  const isDecimal = decimalDTs.includes(itemDataType);
  const isBoolean = booleanDTs.includes(itemDataType);
  const inputProps = isInt
    ? { inputMode: 'numeric', pattern: '-?[0-9]*' }
    : isDecimal
      ? { inputMode: 'decimal', pattern: '-?[0-9]*\\.?[0-9]*' }
      : {};
  const specifiedDataType = getSpecifiedDataType(item);
  let value = getValueOrDefault(item[label]);
  const { uid } = item;
  const placeholder = () => {
    if (isInt) {
      return 'Integer';
    }
    if (isDecimal) {
      return 'Decimal';
    }
    if (isBoolean) {
      return 'Boolean';
    }

    return 'Text';
  };

  if (!Array.isArray(value) && value && isList) {
    value = [];
  } else if (typeof value !== 'object' && isDict) {
    value = {};
  }

  return {
    isList,
    label,
    isListOfDict,
    isDict,
    isDictOfList,
    isInt,
    isDecimal,
    isBoolean,
    inputProps,
    placeholder,
    value,
    specifiedDataType,
    uid,
  };
};

export const prepareArgument = (argument) => {
  let editedArgument = structuredClone(argument);
  editedArgument = editedArgument.name
    ? {
        [argument.name]: '',
        python_datatype: argument.python_datatype,
        is_mandatory: argument.is_mandatory,
        uid: crypto.randomUUID(),
      }
    : argument;

  const argumentDataType = getValueOrDefault(
    editedArgument?.python_datatype?.toLowerCase(),
  );
  const isList = argumentDataType.indexOf('list') === 0;
  const isDict = argumentDataType.indexOf('dict') === 0;
  const isDictOfList = ['dict[list]', 'dict[str,list]'].includes(
    argumentDataType,
  );
  const isListOfDict = ['list[dict]'].includes(argumentDataType);
  let value = editedArgument?.name
    ? ''
    : editedArgument[getValueOrDefault(Object.keys(editedArgument)[0])] || '';
  const specifiedDataType = getSpecifiedDataType(argument);

  editedArgument.canModifyDataType = !argumentDataType;
  editedArgument.canModifyNestedDataType = [
    'dict',
    'list',
    'list[dict]',
    'dict[list]',
  ].includes(argumentDataType);
  editedArgument.uid = crypto.randomUUID();
  const label = getValueOrDefault(Object.keys(editedArgument)[0]);
  if (isList) {
    if (!Array.isArray(value)) {
      value = isListOfDict
        ? [
            {
              [crypto.randomUUID()]: {
                key: '',
                value: '',
                python_datatype: 'string',
              },
            },
          ]
        : [''];
      editedArgument[label] = value;
      editedArgument.ids = [crypto.randomUUID()];
      editedArgument.python_datatypes = [
        isListOfDict ? 'dict' : specifiedDataType || '',
      ];
    }

    const qtyDiff =
      value?.length - (editedArgument?.python_datatypes?.length || 0);
    if (qtyDiff !== 0) {
      editedArgument.python_datatypes = [
        ...(editedArgument?.python_datatypes || []),
        ...Array(Math.abs(qtyDiff)).fill(specifiedDataType || ''),
      ].slice(0, value.length);
    }
  } else if (isDict) {
    if (!isObject(value)) {
      const newKey = '';
      value = isDictOfList
        ? {
            [newKey]: {
              [crypto.randomUUID()]: { value: '', python_datatype: 'str' },
            },
          }
        : { [newKey]: '' };
      const ids = [
        {
          uid: crypto.randomUUID(),
          key: newKey,
        },
      ];
      editedArgument[label] = value;
      editedArgument.ids = ids;
    }

    const qtyDiff =
      Object.keys(value).length -
      (Object.keys(editedArgument?.python_datatypes || {})?.length || 0);
    const editedDataTypes = editedArgument?.python_datatypes
      ? structuredClone(editedArgument?.python_datatypes)
      : {};
    if (qtyDiff !== 0) {
      Object.keys(value).map((valueKey) => {
        if (typeof editedDataTypes.valueKey === 'undefined') {
          editedDataTypes[valueKey] = specifiedDataType || '';
        }
        return valueKey;
      });
      editedArgument.python_datatypes = editedDataTypes;
    }
  }

  return editedArgument;
};

export const handleDataTypeChange = (
  inputDataType: string,
  index: any,
  fieldName: any,
  contextHandleDataTypeChange: any,
  argument: any,
  dispatch: any,
  updateFormField: any,
) => {
  const path = getPathToArguments({
    contextHandleDataTypeChange,
    addToPath: [index],
  });
  const objKey = getObjKeyName(fieldName);
  const editedArgument = structuredClone(argument);
  const { python_datatype, [objKey]: value } = editedArgument;
  editedArgument[objKey] = getNewValue(input, value, python_datatype);
  editedArgument.python_datatype = inputDataType;
  dispatch(updateFormField({ path, value: input }));
};

export const handleValueChange = (
  value: any,
  index: any,
  fieldName: any,
  contextHandleDataTypeChange: any,
  dispatch: any,
  updateFormField: any,
) => {
  const objKey = getObjKeyName(fieldName);
  const input = getInputValue(fieldName, value);

  const path = getPathToArguments({
    contextHandleDataTypeChange,
    addToPath: [index, objKey],
  });
  dispatch(updateFormField({ path, value: input }));
};

export const preventEnterSubmit = (event: React.KeyboardEvent) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
};
