// @ts-nocheck
import { useState, useEffect } from 'react';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  ReportingKPI,
  Condition,
  ConditionArgument,
} from '../../types/analyticsType';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import AddCondition from '../AddCondition/AddCondition';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';

import './AddPreProcessing.css';

const AddPreProcessing = (props: any) => {
  const { analyticsData, colIndex, dataItemIndex } = props;
  const dispatch = useDispatch();
  const pathToConditionsToExclude = [
    'analytics',
    analyticsData,
    dataItemIndex,
    'conditions_to_exclude',
  ];
  const analyticsConditionsToExclude = useSelector(
    (state) =>
      ['formField', ...pathToConditionsToExclude].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || [],
  );
  const [preProcessings, setPreProcessings] = useState<ReportingKPI[]>([]);
  //   remove initial value when more conditions are added in the future
  const [conditions, setConditions] = useState<Condition[]>([
    {
      uid: crypto.randomUUID(),
      name: 'Exclude Data',
      condition_arguments: [],
    },
  ]);
  const [selectedCondition, setSelectedCondition] = useState<Condition>({
    uid: crypto.randomUUID(),
    name: '',
    condition_arguments: [],
  });

  useEffect(() => {
    if (analyticsConditionsToExclude[colIndex].name !== '')
      setSelectedCondition(analyticsConditionsToExclude[colIndex]);
  }, []);

  // uncomment the section below and reformat when more conditions are added in the future
  //   const analyticsSelector = useSelector(
  //     (state: RootState) => state.analytics.analytics
  //   );
  //   const analyticsFieldsSelector = useSelector(
  //     (state: RootState) => state.analytics.analyticsFields
  //   );
  //   useEffect(() => {
  //     const myClonedArray = [];
  //     analyticsFieldsSelector?.forEach((val) =>
  //       myClonedArray.push(Object.assign({}, val))
  //     );
  //     analyticsFieldsSelector.length > 0 && setConditions(myClonedArray);
  //     analyticsSelector.length > 0 && setPreProcessings(analyticsSelector);
  //   }, [analyticsSelector, analyticsFieldsSelector]);

  const AddConditionStep = (e: any) => {
    e.preventDefault();

    const tempCondition = structuredClone(analyticsConditionsToExclude);

    const tempConditionName = tempCondition.find(
      (item) => item.name === analyticsConditionsToExclude[colIndex]?.name,
    );

    tempConditionName.condition_arguments.splice(
      tempConditionName.condition_arguments.length,
      0,
      {
        merchant_id_col: [],
        acquirer_col: [],
        date_col: '',
        uid: crypto.randomUUID(),
      },
    );

    dispatch(
      updateFormField({
        path: pathToConditionsToExclude,
        value: tempCondition,
      }),
    );
  };

  const handleRemoveClick = (e: any, index: number) => {
    e.preventDefault();

    const updatedValues = structuredClone(analyticsConditionsToExclude);

    updatedValues.splice(index, 1);

    dispatch(
      updateFormField({
        path: pathToConditionsToExclude,
        value: updatedValues,
      }),
    );
  };

  const handleSelectedStep = (e: any) => {
    e.preventDefault();
    const selectedStepTemp = conditions?.find(
      (item) => item.name === e.target.value,
    );

    selectedStepTemp.condition_arguments = [];

    setSelectedCondition(selectedStepTemp);
    const updatedValues = structuredClone(analyticsConditionsToExclude);
    updatedValues[colIndex] = selectedStepTemp;
    dispatch(
      updateFormField({
        path: pathToConditionsToExclude,
        value: updatedValues,
      }),
    );
  };

  const handleConditionRemoveClick = (e: any, index: number) => {
    e.preventDefault();

    const updatedValues = structuredClone(analyticsConditionsToExclude);

    updatedValues[colIndex].condition_arguments.splice(index, 1);
    dispatch(
      updateFormField({
        path: pathToConditionsToExclude,
        value: updatedValues,
      }),
    );
  };

  return (
    <div className="column-analytics-div">
      <div className="row-div">
        <FormControl required fullWidth>
          <InputLabel id="demo-simple-select-label1">
            Select Pre-processing
          </InputLabel>
          <Select
            required
            labelId="demo-simple-select-label1"
            id="demo-simple-select1"
            value={analyticsConditionsToExclude[colIndex]?.name || ''}
            label="Select a analytics"
            name="analytics"
            defaultValue="rename_column"
            variant="standard"
            onChange={handleSelectedStep}
          >
            {conditions.length > 0 &&
              conditions.map((item: Condition, index: number) => {
                return (
                  <MenuItem
                    key={index}
                    value={item.name}
                    disabled={analyticsConditionsToExclude?.some(
                      (condition: Condition) =>
                        // remove ---> || item.name !== 'Exclude Data' <--- when other conditions are added in the future
                        condition.name === item.name ||
                        item.name !== 'Exclude Data',
                    )}
                  >
                    {item.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <div>
          <button
            type="button"
            className="remove-merchant-button"
            onClick={(e) => handleRemoveClick(e, colIndex)}
          >
            <RemoveCircleSharpIcon />
          </button>
        </div>
      </div>
      {selectedCondition.name !== '' && (
        <div>
          <div className="add-button-div">
            <p className="add-condition-btn">Add Condition</p>
            <button
              className="add-file-button"
              onClick={(e) => AddConditionStep(e)}
              type="button"
            >
              <AddCircleSharpIcon />
            </button>
          </div>
          {analyticsConditionsToExclude[colIndex]?.condition_arguments?.map(
            (condition: ConditionArgument, conditionIndex: number) => {
              return (
                <div key={condition.uid} className="condition-div">
                  <AddCondition
                    analyticsData={analyticsData}
                    dataItemIndex={dataItemIndex}
                    colIndex={colIndex}
                    condition={condition}
                    conditionIndex={conditionIndex}
                    uid={condition.uid}
                  />
                  <div>
                    <button
                      type="button"
                      className="remove-merchant-button"
                      onClick={(e) =>
                        handleConditionRemoveClick(e, conditionIndex)
                      }
                    >
                      <RemoveCircleSharpIcon />
                    </button>
                  </div>
                </div>
              );
            },
          )}
        </div>
      )}
    </div>
  );
};

export default AddPreProcessing;
