// @ts-nocheck
import React from 'react';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import AddPageSection from '../AddPageSection/AddPageSection';
import { AdditionalTable, Page, PageSection } from '../../types/analyticsType';
import { TextField } from '@mui/material';
import AddReportingKPI from '../AddReportingKPI/AddReportingKPI';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';
import { toggleAccordion } from '../../slices/accordionSlice';
import { preventEnterSubmit } from '../../utils/inputs';

import './ReportPage.css';

const ReportPage = ({ analyticsData, page, pageIndex, additional }: any) => {
  const dispatch = useDispatch();
  const formFieldAnalyticsData = useSelector(
    (state) => state.formField.analytics[analyticsData],
  );
  const expandedAccordions = useSelector(
    (state: any) => state.accordion.expandedAccordions,
  );
  // handle click event of the Add button
  const handleAddPageSection = (e: any) => {
    e.preventDefault();
    let tempPageSections = structuredClone(
      formFieldAnalyticsData[pageIndex].page_sections,
    ) as PageSection[];

    tempPageSections = [
      ...tempPageSections,
      {
        output_table_id: `${pageIndex + 1}${String.fromCharCode(
          tempPageSections.length + 65,
        )}`,
        output_table_name: '',
        analysis_generation: [],
        uid: crypto.randomUUID(),
      },
    ];

    dispatch(
      updateFormField({
        path: ['analytics', analyticsData],
        value: structuredClone(formFieldAnalyticsData)?.map((item: Page) =>
          item.output_page_number === page.output_page_number
            ? { ...item, page_sections: tempPageSections }
            : item,
        ),
      }),
    );
  };

  const handleRemoveClick = (e: any, index: number) => {
    e.preventDefault();
    const tempAnalytics = structuredClone(formFieldAnalyticsData);

    tempAnalytics.splice(index, 1);

    if (!additional) {
      tempAnalytics.map((item: Page, itemIndex: number) => {
        item.output_page_number = itemIndex + 1;
        return item;
      });
    }
    dispatch(
      updateFormField({
        path: ['analytics', analyticsData],
        value: tempAnalytics,
      }),
    );
  };

  const handleValueChange = (e: any) => {
    e.preventDefault();
    const tempPageSection = structuredClone(formFieldAnalyticsData[pageIndex]);

    tempPageSection.output_name = e.target.value;

    dispatch(
      updateFormField({
        path: ['analytics', analyticsData],
        value: structuredClone(formFieldAnalyticsData)?.map(
          (item: AdditionalTable, itemIndex: number) =>
            itemIndex === pageIndex
              ? {
                  ...item,
                  output_name: tempPageSection.output_name,
                }
              : item,
        ),
      }),
    );
  };

  return (
    <MuiAccordion
      id={page.uid}
      expanded={expandedAccordions.includes(page.uid)}
      onChange={() => dispatch(toggleAccordion(page.uid))}
    >
      <MuiAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ArrowForwardIosSharpIcon />}
      >
        {additional ? (
          <Typography>
            {formFieldAnalyticsData?.[pageIndex]?.output_name || ''}
          </Typography>
        ) : (
          <Typography>{`Page ${page?.output_page_number}`}</Typography>
        )}
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        <div
          style={{
            display: 'grid',
            gap: '20px',
            gridTemplateColumns: '5fr .5fr',
          }}
        >
          <div>
            <div className="data-source-type-inner-div">
              {additional ? (
                <div>
                  <TextField
                    required
                    label="Reporting Table Name"
                    variant="standard"
                    value={
                      formFieldAnalyticsData?.[pageIndex]?.output_name || ''
                    }
                    onChange={handleValueChange}
                    sx={{ marginBottom: '10px' }}
                    onKeyDown={preventEnterSubmit}
                  />
                  <AddReportingKPI
                    analyticsData={analyticsData}
                    page={page}
                    pageIndex={pageIndex}
                    additional={additional}
                  />
                </div>
              ) : (
                <div>
                  <div className="add-data-source-div">
                    <p className="add-page-section-btn">Add a Page Section</p>
                    <div className="btn-box acquirer-button-div">
                      <button
                        className="add-merchant-button"
                        onClick={(e) => handleAddPageSection(e)}
                      >
                        <AddCircleSharpIcon />
                      </button>
                    </div>
                  </div>
                  {formFieldAnalyticsData[pageIndex]?.page_sections?.map(
                    (pageSection: Page, pageSectionIndex: number) => {
                      return (
                        <div key={pageSection.uid}>
                          <AddPageSection
                            analyticsData={analyticsData}
                            page={page}
                            pageIndex={pageIndex}
                            pageSection={pageSection}
                            pageSectionIndex={pageSectionIndex}
                            additional={additional}
                          />
                        </div>
                      );
                    },
                  )}
                </div>
              )}
            </div>
          </div>
          <div>
            <button
              type="button"
              className="remove-merchant-button"
              onClick={(e) => handleRemoveClick(e, pageIndex)}
            >
              <RemoveCircleSharpIcon />
            </button>
          </div>
        </div>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default ReportPage;
