// @ts-nocheck
import * as React from 'react';
import { Box, Typography, Stack, TextField } from '@mui/material';
import { AddCircleSharp } from '@mui/icons-material';
import NumericTextField from '../NumericTextField/NumericTextField';
import InputSelectType from './InputSelectType';
import ButtonRemove from './ButtonRemove';
import SubList from './SubList';
import {
  getDataType,
  getPlaceholder,
  handleDataTypeChange,
  getArgumentInformations,
  getValueOrDefault,
  getNewValue,
  getObjKeyName,
  getPathToArguments,
  getInputValue,
  preventEnterSubmit,
} from '../../utils/inputs';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';
import './ItemList.css';

const ItemList = ({ item, index, contextHandleDataTypeChange }) => {
  const { label, isListOfDict, inputProps, value, specifiedDataType } =
    getArgumentInformations(item);
  const dispatch = useDispatch();
  const objKey = getObjKeyName(item);
  const isRequired = item[Object.keys(item)[2]];
  const pathToArgument = getPathToArguments({
    contextHandleDataTypeChange,
    addToPath: [index],
  });
  const argument = useSelector(
    (state) =>
      ['formField', ...pathToArgument].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || {},
  );

  const handleDataTypeInListChange = (input: string, indexInList: number) => {
    const editedArgument = structuredClone(argument);
    const value = getValueOrDefault(
      (editedArgument[objKey] || [''])[indexInList],
    );
    editedArgument[objKey] = (editedArgument[objKey] || ['']).map((x, i) =>
      i === indexInList
        ? getNewValue(
            input,
            value,
            editedArgument?.python_datatypes?.[indexInList],
          )
        : x,
    );
    if (!Array.isArray(editedArgument?.python_datatypes)) {
      editedArgument.python_datatypes = [];
    }
    editedArgument.python_datatypes[indexInList] = input;
    dispatch(updateFormField({ path: pathToArgument, value: editedArgument }));
  };

  const handleDataTypeInListDictChange = (
    input,
    pathToDictItemFromArgument,
  ) => {
    const editedDictItem = structuredClone(
      pathToDictItemFromArgument.reduce(
        (currentState, pathPart) => currentState[pathPart],
        argument,
      ),
    );
    const value = editedDictItem.value;

    editedDictItem.value = getNewValue(input, value);
    editedDictItem.python_datatype = input;
    dispatch(
      updateFormField({
        path: [...pathToArgument, ...pathToDictItemFromArgument],
        value: editedDictItem,
      }),
    );
  };

  const deleteExistingKeysInListOfDict = (listIndex) => {
    const editedArgumentItem = structuredClone(argument[objKey][listIndex]);

    const keys = new Set();
    const result = {};
    let newSetExists = false;
    for (const id in editedArgumentItem) {
      if (!keys.has(editedArgumentItem[id].key)) {
        keys.add(editedArgumentItem[id].key);
        result[id] = editedArgumentItem[id];
      } else {
        newSetExists = true;
      }
    }

    if (newSetExists) {
      dispatch(
        updateFormField({
          path: [...pathToArgument, objKey, listIndex],
          value: result,
        }),
      );
    }
  };

  const handleNewListDictValue = () => {
    let editedArgumentItem = structuredClone(argument[objKey]);
    const newDictItem = {
      [crypto.randomUUID()]: { key: '', value: '', python_datatype: 'string' },
    };
    editedArgumentItem = Array.isArray(editedArgumentItem)
      ? [...editedArgumentItem, newDictItem]
      : [newDictItem];
    dispatch(
      updateFormField({
        path: [...pathToArgument, objKey],
        value: editedArgumentItem,
      }),
    );
  };

  const handleListDictKeyChange = (value, pathToDictItemFromArgument) => {
    dispatch(
      updateFormField({
        path: [...pathToArgument, ...pathToDictItemFromArgument, 'key'],
        value: value,
      }),
    );
  };

  const handleListDictValueChange = (
    value: any,
    pathToDictItemFromArgument,
  ) => {
    const editedDictItem = structuredClone(
      pathToDictItemFromArgument.reduce(
        (currentState, pathPart) => currentState[pathPart],
        argument,
      ),
    );
    const input = getInputValue(editedDictItem, value);

    dispatch(
      updateFormField({
        path: [...pathToArgument, ...pathToDictItemFromArgument, 'value'],
        value: input,
      }),
    );
  };

  const handleRemoveListDictValue = (listIndex, dictId) => {
    const editedDictItem = structuredClone(argument[objKey][listIndex]);

    if (dictId in editedDictItem) {
      delete editedDictItem[dictId];
    }
    dispatch(
      updateFormField({
        path: [...pathToArgument, objKey, listIndex],
        value: editedDictItem,
      }),
    );
  };

  const handleNewDictValueInList = (listIndex) => {
    const editedArgumentItem = structuredClone(argument[objKey][listIndex]);
    editedArgumentItem[crypto.randomUUID()] = {
      key: '',
      value: '',
      python_datatype: 'string',
    };

    dispatch(
      updateFormField({
        path: [...pathToArgument, objKey, listIndex],
        value: editedArgumentItem,
      }),
    );
  };

  const handleListValueChange = (
    value: any,
    indexInList: number,
    dataType: any,
  ) => {
    const editedArgument = structuredClone(argument);
    const input = getInputValue({ python_datatype: dataType }, value);
    editedArgument[objKey] = (editedArgument[objKey] || ['']).map((x, i) =>
      i === indexInList ? input : x,
    );
    dispatch(updateFormField({ path: pathToArgument, value: editedArgument }));
  };
  const handleNewListValue = () => {
    const editedArgument = structuredClone(argument);
    editedArgument[objKey] = Array.isArray(editedArgument[objKey])
      ? structuredClone([...editedArgument[objKey], ''])
      : [''];

    editedArgument.python_datatypes = Array.isArray(
      editedArgument.python_datatypes,
    )
      ? structuredClone([
          ...editedArgument.python_datatypes,
          specifiedDataType || '',
        ])
      : [specifiedDataType || ''];

    if (
      !editedArgument.ids ||
      editedArgument[objKey].length > editedArgument?.ids.length
    ) {
      editedArgument.ids = Array.isArray(editedArgument.ids)
        ? structuredClone([...editedArgument.ids, crypto.randomUUID()])
        : [crypto.randomUUID()];
    }
    dispatch(updateFormField({ path: pathToArgument, value: editedArgument }));
  };

  const handleRemoveListValue = (indexInList: number) => {
    const editedArgument = structuredClone(argument);

    if (Array.isArray(editedArgument[objKey])) {
      editedArgument[objKey].splice(indexInList, 1);
    } else if (indexInList in editedArgument[objKey]) {
      delete editedArgument[objKey][indexInList];
    }

    if (Array.isArray(editedArgument.ids)) {
      editedArgument.ids.splice(indexInList, 1);
    } else if (indexInList in editedArgument.ids) {
      delete editedArgument.ids[indexInList];
    }

    if (Array.isArray(editedArgument.python_datatypes)) {
      editedArgument.python_datatypes.splice(indexInList, 1);
    } else if (indexInList in editedArgument.python_datatypes) {
      delete editedArgument.python_datatypes[indexInList];
    }
    dispatch(updateFormField({ path: pathToArgument, value: editedArgument }));
  };

  return (
    <div className="cmp-list">
      <Box mb={1} className="cmp-list__header">
        <Typography
          data-testid="clq3nz9qd00005s673ekj66et"
          display="inline-block"
          color="primary"
          ml={2}
          mb={1}
          mt={2}
        >
          {label}
          {isRequired ? <>&#xFE61;</> : <></>}
        </Typography>
        {item?.canModifyDataType && (
          <InputSelectType
            value={item.python_datatype}
            handleOnChange={(e) =>
              handleDataTypeChange(
                e.target.value,
                index,
                item,
                contextHandleDataTypeChange,
                argument,
                dispatch,
                updateFormField,
              )
            }
            formControlProps={{
              sx: { mr: 1 },
            }}
            selectProps={{
              sx: { minWidth: 110 },
              'data-testid': '096d4669-b6b8-4260-b799-0be10edf54f2',
            }}
            listType="dataType"
          />
        )}
        <Box
          component="button"
          ml={4}
          className="add-file-button"
          onClick={() =>
            isListOfDict ? handleNewListDictValue() : handleNewListValue()
          }
          type="button"
          data-testid="3866217b-8bf9-4cb2-8c15-6d1bc553ad2e"
        >
          <AddCircleSharp />
          <Box component="span" ml={0.5}>
            {isListOfDict ? 'Add key-value dictionary' : 'Add value'}
          </Box>
        </Box>
      </Box>
      <div className="cmp-list__items">
        {value.map((v: any, listIndex: any) => {
          const key = isListOfDict
            ? argument?.ids?.[listIndex]?.innerDictId
            : argument?.ids?.[listIndex];
          const dataType =
            specifiedDataType ||
            getDataType(
              isListOfDict
                ? item.python_datatype
                : item?.python_datatypes?.[listIndex],
            );
          return (
            <Box key={key} className="cmp-list__item">
              {isListOfDict ? (
                <Box mb={5}>
                  <Box
                    gap={2}
                    alignItems="center"
                    mb={1}
                    ml={4}
                    className="cmp-list__dict-header"
                  >
                    <Typography
                      data-testid="clq3nzvdq00015s67ev2tfjkz"
                      color="primary"
                      ml={2}
                    >
                      {listIndex + 1}
                      {isRequired ? <>&#xFE61;</> : <></>}
                    </Typography>
                    <Stack>
                      <button
                        className="add-file-button btn-list-dict cmp-list__btn-add-item"
                        onClick={() => handleNewDictValueInList(listIndex)}
                        type="button"
                        disabled={Object.keys(v).some((x) => !x)}
                        data-testid="d7640feb-f296-4351-acf3-68c31d1bfd87"
                      >
                        <AddCircleSharp />{' '}
                        <Box component="span" ml={0.5}>
                          Add key-value pair
                        </Box>
                      </button>
                      <ButtonRemove
                        label="Remove key-value dictionary"
                        onRemove={() => handleRemoveListValue(listIndex)}
                        buttonProps={{
                          'data-testid': '76a6d367-c80b-4e0f-abad-8c48544051b9',
                        }}
                        className="remove-merchant-button cmp-list__btn-remove-item"
                        isDisabled={value.length === 1}
                      />
                    </Stack>
                  </Box>

                  <Box ml={6}>
                    {Object.keys(v)?.map((dictId, dictIndex) => {
                      const dictItem = v[dictId];
                      const dictDataType = dictItem.python_datatype;
                      const dictValue = dictItem.value;
                      const dictKey = dictItem.key;
                      const pathToDictItemFromArgument = [
                        objKey,
                        listIndex,
                        dictId,
                      ];
                      return (
                        <Box
                          key={dictId}
                          display="flex"
                          alignItems="center"
                          gap={2}
                          data-testid="bf2157ed-28f0-4ca4-86e1-a38e2479c53c"
                          mb={1}
                          className="cmp-dict__row"
                        >
                          <TextField
                            required={isRequired}
                            type="text"
                            label={'key ' + (dictIndex + 1)}
                            variant="standard"
                            value={dictKey}
                            onChange={(e) => {
                              handleListDictKeyChange(
                                e.target.value,
                                pathToDictItemFromArgument,
                              );
                            }}
                            onBlur={(e) => {
                              deleteExistingKeysInListOfDict(listIndex);
                            }}
                            sx={{
                              ml: 4,
                            }}
                            placeholder={getPlaceholder()}
                            data-testid="a40d43c8-890c-4cd6-90ba-3323c6f602c5"
                            onKeyDown={preventEnterSubmit}
                          />

                          {['boolean', 'bool'].includes(dictDataType) ? (
                            <InputSelectType
                              label={'value ' + (dictIndex + 1)}
                              value={dictValue?.toString()}
                              handleOnChange={(e: any) =>
                                handleListDictValueChange(
                                  e.target.value,
                                  pathToDictItemFromArgument,
                                )
                              }
                              selectProps={{
                                type: getDataType(dictDataType),
                                required: isRequired,
                                sx: { minWidth: 110 },
                                'data-testid':
                                  '8c49271b-c28a-456d-9c99-fac67f8debd512',
                              }}
                              formControlProps={{
                                required: isRequired,
                              }}
                              listType="dataTypeBoolean"
                            />
                          ) : (
                            dictDataType !== 'list' && (
                              <NumericTextField
                                required={isRequired}
                                type={getDataType(dictDataType)}
                                label={'value ' + (dictIndex + 1)}
                                variant="standard"
                                value={dictValue}
                                onChange={(e: any) => {
                                  handleListDictValueChange(
                                    e.target.value,
                                    pathToDictItemFromArgument,
                                  );
                                }}
                                inputProps={inputProps}
                                placeholder={getPlaceholder(dictDataType)}
                                data-testid="06e4c104-0791-4ab3-82eb-d41f2b8dbbb542"
                                dataType={dictDataType}
                              />
                            )
                          )}
                          <InputSelectType
                            label="Data type"
                            value={dictDataType}
                            handleOnChange={(e: any) =>
                              handleDataTypeInListDictChange(
                                e.target.value,
                                pathToDictItemFromArgument,
                              )
                            }
                            formControlProps={{
                              sx: { ml: 1 },
                            }}
                            selectProps={{
                              sx: { minWidth: 110 },
                              'data-testid':
                                'd0938139-893a-40d8-ac16-26e1403466ff',
                            }}
                            listType="dataTypeBasic"
                          />
                          <ButtonRemove
                            label="Remove key-value pair"
                            onRemove={() =>
                              handleRemoveListDictValue(listIndex, dictId)
                            }
                            buttonProps={{
                              'data-testid':
                                'a3a53844-5786-4852-a602-b38efda8e110',
                            }}
                            isDisabled={Object.keys(v).length === 1}
                            className="remove-merchant-button btn-list-dict"
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              ) : (
                <>
                  <Box display="flex" gap={1} alignItems="center" mb={1}>
                    {['bool', 'boolean'].includes(dataType) ? (
                      <InputSelectType
                        label={listIndex + 1}
                        value={v?.toString()}
                        handleOnChange={(e) =>
                          handleListValueChange(
                            e.target.value,
                            listIndex,
                            item?.python_datatypes?.[listIndex],
                          )
                        }
                        formControlProps={{
                          required: isRequired,
                          sx: { ml: 4 },
                        }}
                        selectProps={{
                          type: item?.python_datatypes?.[listIndex],
                          required: isRequired,
                          sx: { minWidth: 167 },
                          'data-testid': '21a9708e-0603-42f5-99dd-4ea0f7587321',
                        }}
                        listType="dataTypeBoolean"
                      />
                    ) : (
                      dataType !== 'list' && (
                        <NumericTextField
                          required={isRequired}
                          type={getDataType(dataType)}
                          label={listIndex + 1}
                          variant="standard"
                          value={v?.toString()}
                          onChange={(e) =>
                            handleListValueChange(
                              e.target.value,
                              listIndex,
                              item?.python_datatypes?.[listIndex],
                            )
                          }
                          sx={{
                            ml: 4,
                          }}
                          inputProps={inputProps}
                          placeholder={getPlaceholder(
                            item.python_datatypes?.[listIndex],
                          )}
                          data-testid="2257dd0e-e300-492f-ad2a-681c6fa53bb9"
                          dataType={item?.python_datatypes?.[listIndex]}
                        />
                      )
                    )}
                    {item?.canModifyNestedDataType && (
                      <InputSelectType
                        label={
                          dataType === 'list'
                            ? `${listIndex + 1}* Data type`
                            : 'Data type'
                        }
                        value={item?.python_datatypes?.[listIndex]}
                        handleOnChange={(e) =>
                          handleDataTypeInListChange(e.target.value, listIndex)
                        }
                        formControlProps={{
                          sx: { ml: 4 },
                        }}
                        selectProps={{
                          sx: { minWidth: 110 },
                          'data-testid': '3beb8264-4517-466f-ae4b-b5084f4f601c',
                        }}
                        listType="dataTypeBasicWithList"
                      />
                    )}
                    <ButtonRemove
                      label="Remove value"
                      onRemove={() => handleRemoveListValue(listIndex)}
                      buttonProps={{
                        'data-testid': '1addf90f-ef2a-4053-a943-3b867ed55453',
                      }}
                      isDisabled={value.length === 1}
                      className="remove-merchant-button cmp-list__btn-remove-item"
                    />
                  </Box>
                  {dataType === 'list' && (
                    <SubList
                      subList={v}
                      inputProps={inputProps}
                      index={index}
                      item={item}
                      listIndex={listIndex}
                      contextHandleDataTypeChange={contextHandleDataTypeChange}
                    />
                  )}
                </>
              )}
            </Box>
          );
        })}
      </div>
    </div>
  );
};

export default ItemList;
