// @ts-nocheck
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export default function InputSelectType({
  label = 'Data type',
  value = '',
  handleOnChange,
  formControlProps = {},
  selectProps = {},
  labelProps = {},
  listType = 'dataTypeBasic',
  disabled = false,
}) {
  const dataTypeBasicSelectList = {
    str: 'Text',
    int: 'Integer',
    double: 'Decimal',
    bool: 'Boolean',
  };

  const dataTypeBasicWithListSelectList = {
    ...dataTypeBasicSelectList,
    list: 'List',
  };

  const dataTypeSelectList = {
    ...dataTypeBasicWithListSelectList,
    dict: 'Dictionary',
  };

  const dataTypeBooleanSelectList = {
    true: 'Yes',
    false: 'No',
  };

  const getValue = (value) => {
    const typeMapping = {
      bool: 'bool',
      boolean: 'bool',
      float: 'double',
      number: 'int',
      decimal: 'double',
      double: 'double',
      int: 'int',
      integer: 'int',
      string: 'str',
      str: 'str',
      list: 'list',
      dict: 'dict',
      true: 'true',
      false: 'false',
    };

    const x = value?.toLowerCase() || '';
    return typeMapping[x] || '';
  };

  const editedValue = getValue(value);

  const typeSelectListMapping: { [key: string]: any } = {
    dataTypeBoolean: dataTypeBooleanSelectList,
    dataType: dataTypeSelectList,
    dataTypeBasic: dataTypeBasicSelectList,
    dataTypeBasicWithList: dataTypeBasicWithListSelectList,
  };

  const getSelectList = (type: string) => {
    const list = typeSelectListMapping[type] || dataTypeBasicWithListSelectList;
    return Object.keys(list).map((item: any) => (
      <MenuItem key={item} value={item}>
        {list[item]}
      </MenuItem>
    ));
  };
  const selectList = getSelectList(listType);
  const availableValues = selectList.map((item) => item.props.value);
  const validValue = availableValues.includes(editedValue) ? editedValue : '';

  return (
    <FormControl {...formControlProps} disabled={disabled}>
      <InputLabel {...labelProps} disabled={disabled}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        value={validValue}
        label={label}
        onChange={handleOnChange}
        {...selectProps}
      >
        {selectList}
      </Select>
    </FormControl>
  );
}
