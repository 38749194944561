// @ts-nocheck
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import CopyFile from '../CopyFile/CopyFile';
import { IconButton, Tooltip, FormHelperText } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddTransformation from '../../components/AddTransformation/AddTransformation';
import { File } from '../../types/fileType';
import { Field } from '../../types/transformationType';
import { TRANSFORMATIONS } from '../../constants/file';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';

import {
  setExpandedAccordions,
  toggleAccordion,
} from '../../slices/accordionSlice';
import { TEXT } from '../../constants/text';
const TransformationsAccordion = ({
  pathToSourceType,
  fileItem,
  fileIndex,
  dataType,
  dataSourceIndex,
  dataTypeIndex,
}: any) => {
  const dispatch = useDispatch();
  const pathToTransformation = [
    ...pathToSourceType,
    'file',
    fileIndex,
    'transformation',
  ];
  const formFieldTransformations = useSelector(
    (state) =>
      ['formField', ...pathToTransformation].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || {},
  );
  const expandedAccordions = useSelector(
    (state: any) => state.accordion.expandedAccordions,
  );
  const accordionId = `transformations-${fileItem.uid}`;
  const isAccordionExpanded = expandedAccordions.includes(accordionId);
  useEffect(() => {
    let shouldAddId = false;
    if (formFieldTransformations) {
      const updatedTransformations = formFieldTransformations.map((item) => {
        if (!item.uid) {
          shouldAddId = true;
          return { ...structuredClone(item), uid: crypto.randomUUID() };
        }
        return structuredClone(item);
      });

      if (shouldAddId) {
        dispatch(
          updateFormField({
            path: pathToTransformation,
            value: updatedTransformations,
          }),
        );
      }
    }
  }, [formFieldTransformations]);

  const [hoveredTransformationStep, setTransformationHoveredStep] =
    useState(-1);
  const [selectedFileTransformationIndex, setSelectedFileTransformationIndex] =
    useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File>({});
  const filesSelector = useSelector((state: RootState) => state.files.files);

  const AddTransformationFile = (
    e: any,
    index: number = formFieldTransformations.length,
  ) => {
    e.preventDefault();
    const editedFormFieldTransformations = structuredClone(
      formFieldTransformations,
    );
    editedFormFieldTransformations.splice(index, 0, {
      uid: crypto.randomUUID(),
      name: '',
      description: '',
      arguments: [],
    });
    dispatch(
      updateFormField({
        path: pathToTransformation,
        value: editedFormFieldTransformations,
      }),
    );
  };

  const collapseAllTransformations = () => {
    const newArray = formFieldTransformations.map(
      (transformation) => transformation.uid,
    );
    const filteredAccordions = expandedAccordions.filter(
      (accordion) => !newArray.includes(accordion),
    );

    dispatch(setExpandedAccordions(filteredAccordions));
  };

  const expandAllTransformations = () => {
    const newArray = [];

    formFieldTransformations.forEach((transformation) => {
      if (!expandedAccordions.includes(transformation.uid)) {
        newArray.push(transformation.uid);
      }
    });
    dispatch(setExpandedAccordions([...expandedAccordions, ...newArray]));
  };

  const copyTransformationFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (selectedFile.file_name != null) {
      const editedSelectedFileTransformation = structuredClone(
        selectedFile?.transformation,
      ).map((item) => {
        return {
          ...item,
          uid: crypto.randomUUID(),
        };
      });
      const editedFormFieldTransformations = structuredClone([
        ...formFieldTransformations,
        ...editedSelectedFileTransformation,
      ]);

      dispatch(
        updateFormField({
          path: pathToTransformation,
          value: editedFormFieldTransformations,
        }),
      );
      enqueueSnackbar(
        `Transformations copied from ${selectedFile.file_name} successfully`,
        {
          variant: 'success',
        },
      );
    }
    setSelectedFileTransformationIndex('');
    setSelectedFile({});
  };

  const handleSelectTransformationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const tempSelectedFileIndex = Number(
      Object.keys(filesSelector).find(
        (index) => index === event.target.value.toString(),
      ),
    );

    setSelectedFileTransformationIndex(event.target.value);
    setSelectedFile(filesSelector[tempSelectedFileIndex]);
  };

  const getTransformationStepIndex = (index) => {
    setTransformationHoveredStep(index);
  };

  return (
    <MuiAccordion
      id={accordionId}
      expanded={isAccordionExpanded}
      onChange={() => dispatch(toggleAccordion(accordionId))}
    >
      <MuiAccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        expandIcon={<ArrowForwardIosSharpIcon />}
      >
        <Typography>Transformations</Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        {/* add transformation step at the beginning */}
        <div className="add-file-info-text">
          <FormHelperText>
            {TEXT.message_above_add_transformation}
          </FormHelperText>
        </div>
        <div className="add-file-div">
          <div className="add-button-div">
            <p className="bold">Add Transformations</p>
            <button
              className="add-file-button"
              onClick={(e) => AddTransformationFile(e, 0)}
              type="button"
            >
              <AddCircleSharpIcon />
            </button>
          </div>
          <Tooltip title="Collapse All">
            <IconButton onClick={collapseAllTransformations}>
              <UnfoldLessIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Expand All">
            <IconButton onClick={expandAllTransformations}>
              <UnfoldMoreIcon />
            </IconButton>
          </Tooltip>
          {/* copy transformation steps from another file */}
          <CopyFile
            selectedValue={selectedFileTransformationIndex}
            onChangeSelect={handleSelectTransformationChange}
            selectItems={filesSelector}
            onSubmitButton={copyTransformationFiles}
            isDisabledSubmit={selectedFile.file_name === undefined}
          />
        </div>
        <div>
          {isAccordionExpanded && (
            <div className="col-div">
              {formFieldTransformations
                ? formFieldTransformations?.map(
                    (
                      transformationItem: Field,
                      transformationIndex: number,
                    ) => {
                      return (
                        <div
                          key={transformationItem.uid}
                          // get transformation step index on hover
                          onMouseEnter={() =>
                            getTransformationStepIndex(transformationIndex)
                          }
                          onMouseLeave={() => getTransformationStepIndex(-1)}
                        >
                          <MuiAccordion
                            id={transformationItem.uid}
                            key={transformationItem.uid}
                            onChange={() =>
                              dispatch(toggleAccordion(transformationItem.uid))
                            }
                            expanded={expandedAccordions.includes(
                              transformationItem.uid,
                            )}
                          >
                            <MuiAccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                              expandIcon={<ArrowForwardIosSharpIcon />}
                            >
                              <Typography>
                                {TRANSFORMATIONS[transformationItem.name] ||
                                  transformationItem.name
                                    .replace(/_/g, ' ')
                                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                                      letter.toUpperCase(),
                                    )}
                              </Typography>
                            </MuiAccordionSummary>
                            <MuiAccordionDetails>
                              <AddTransformation
                                dataType={dataType}
                                colItem={transformationItem}
                                colIndex={transformationIndex}
                                pathToSourceType={pathToSourceType}
                                dataSourceIndex={dataSourceIndex}
                                dataTypeIndex={dataTypeIndex}
                                fileIndex={fileIndex}
                              />
                            </MuiAccordionDetails>
                          </MuiAccordion>
                          {/* Add transformation step at index */}
                          <div
                            className={`step-add-button ${
                              transformationIndex !==
                                formFieldTransformations.length - 1 &&
                              hoveredTransformationStep === transformationIndex
                                ? ''
                                : 'hide-step-add-button'
                            } ${
                              transformationIndex ===
                              formFieldTransformations.length - 1
                                ? 'last-step-add-button'
                                : ''
                            }`}
                          >
                            <button
                              className="add-file-button"
                              onClick={(e) =>
                                AddTransformationFile(
                                  e,
                                  transformationIndex + 1,
                                )
                              }
                              type="button"
                            >
                              <AddCircleSharpIcon />
                            </button>
                          </div>
                        </div>
                      );
                    },
                  )
                : enqueueSnackbar('Add a step or select a file to copy from', {
                    variant: 'warning',
                  })}
            </div>
          )}
        </div>
        {/* add transformation step at the end */}
        {formFieldTransformations?.length ? (
          <div className="add-file-div">
            <button
              className="add-file-button"
              onClick={(e) =>
                AddTransformationFile(e, formFieldTransformations.length)
              }
              type="button"
            >
              <AddCircleSharpIcon />
            </button>
          </div>
        ) : (
          ''
        )}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default TransformationsAccordion;
