// @ts-nocheck
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import TextField from '@mui/material/TextField';
import { TagsInput } from '../../components/TagsInput/TagsInput';
import ApiDataSource from '../../components/ApiDataSource/ApiDataSource';
import AzureDataSource from '../../components/AzureDataSource/AzureDataSource';
import MftDataSource from '../../components/MftDataSource/MftDataSource';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useSelector, useDispatch } from 'react-redux';
import { updateAcquirerDataSources } from '../../slices/formFieldSlice';
import { toggleAccordion } from '../../slices/accordionSlice';
import './AddAcquirerDataSource.css';
import MultiEmail, { handleEmailChange } from '../MultiEmail/MultiEmail';
import { preventEnterSubmit } from '../../utils/inputs';

const AddAcquirerDataSource = () => {
  const dispatch = useDispatch();
  const expandedAccordions = useSelector(
    (state) => state.accordion.expandedAccordions,
  );
  const acquirerDataSources = useSelector(
    (state) => state.formField.acquirer_data_sources,
  );

  const handleInputChange = (
    e: SelectChangeEvent,
    index: any,
    item: any,
    j: any,
  ) => {
    e.preventDefault();
    const updatedValues = structuredClone(acquirerDataSources);
    updatedValues[index].data_source_type[j].selected_type = e.target.value;

    if (e.target.value === 'Api') {
      updatedValues[index].data_source_type[j].url = '';
      updatedValues[index].data_source_type[j].user_name = '';
      updatedValues[index].data_source_type[j].key = '';
      updatedValues[index].data_source_type[j].password = '';
    } else if (e.target.value === 'azure') {
      updatedValues[index].data_source_type[j].url = '';
      updatedValues[index].data_source_type[j].sas_token = '';
    } else {
      updatedValues[index].data_source_type[j].email = [];
    }
    updatedValues[index].data_source_type[j].file = [];
    updatedValues[index].data_source_type[j].file_count =
      updatedValues[index].data_source_type[j].file.length;

    dispatch(updateAcquirerDataSources({ data: updatedValues }));
  };

  const handleAddDataSourceClick = (e: any, i: any, item: any) => {
    e.preventDefault();
    const updatedValues = structuredClone(acquirerDataSources);
    updatedValues[i].data_source_type = [
      ...updatedValues[i].data_source_type,
      { uid: crypto.randomUUID(), selected_type: '' },
    ];
    dispatch(updateAcquirerDataSources({ data: updatedValues }));
  };

  const handleRemoveDataSourceClick = (e: any, i: any, j: any, item: any) => {
    e.preventDefault();
    const updatedValues = structuredClone(acquirerDataSources);
    updatedValues[i].data_source_type.splice(j, 1);
    dispatch(updateAcquirerDataSources({ data: updatedValues }));
  };

  const handleValueChange = (index: any, e: any, fieldName: any, item: any) => {
    e.preventDefault();
    const updatedValues = structuredClone(acquirerDataSources);
    updatedValues[index][fieldName] = e.target.value;
    dispatch(updateAcquirerDataSources({ data: updatedValues }));
  };

  const handleMerchantValueChange = (
    val: any,
    fileIndex: any,
    fieldName: any,
  ) => {
    const updatedValues = structuredClone(acquirerDataSources);
    updatedValues[fileIndex][fieldName] = val;
    dispatch(updateAcquirerDataSources({ data: updatedValues }));
  };

  // handle click event of the Remove button
  const handleRemoveClick = (e: any, index: any, item: any) => {
    e.preventDefault();
    const updatedValues = structuredClone(acquirerDataSources);
    updatedValues.splice(index, 1);
    dispatch(updateAcquirerDataSources({ data: updatedValues }));
  };

  // handle click event of the Add button
  const handleAddClick = (e: any) => {
    e.preventDefault();
    dispatch(
      updateAcquirerDataSources({
        data: [
          ...structuredClone(acquirerDataSources),
          {
            acquirer_name: '',
            acquirer_email: [],
            merchants: [],
            uid: crypto.randomUUID(),
            data_source_type: [],
          },
        ],
      }),
    );
  };

  return (
    <>
      <div className="add-data-source-div">
        <p style={{ fontWeight: 'bold' }}>Add Acquirer</p>
        <div className="btn-box acquirer-button-div">
          <button
            className="add-merchant-button"
            onClick={(e: any) => handleAddClick(e)}
          >
            <AddCircleSharpIcon />
          </button>
        </div>
      </div>
      {acquirerDataSources?.map((item: any, i: any) => {
        const { uid } = item;
        const isAccordionExpanded = expandedAccordions.includes(uid);
        const isSubAccordionExpanded = expandedAccordions.includes(
          `data_source_type-${uid}`,
        );
        return (
          <MuiAccordion
            id={uid}
            expanded={isAccordionExpanded}
            onChange={() => dispatch(toggleAccordion(uid))}
            key={uid}
          >
            <MuiAccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<ArrowForwardIosSharpIcon />}
            >
              <Typography>{item.acquirer_name}</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
              <div
                key={uid}
                style={{
                  display: 'grid',
                  gap: '20px',
                  gridTemplateColumns: '5fr .5fr',
                }}
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      flexDirection: 'column',
                    }}
                    className="data-source-type-inner-div"
                  >
                    <div className="div-style">
                      <TextField
                        required
                        id="acquirer_name"
                        label="Acquirer Name"
                        variant="standard"
                        value={item.acquirer_name}
                        onChange={(e: any) =>
                          handleValueChange(i, e, 'acquirer_name', item)
                        }
                        onKeyDown={preventEnterSubmit}
                      />
                      <div>
                        <MultiEmail
                          className="react-multi-email-div--white"
                          id={`acquirer_email`}
                          label="Acquirer Email(s) (not used for data requests)"
                          emailsArray={item.acquirer_email}
                          handleOnChange={(_emails) => {
                            handleEmailChange(
                              i,
                              _emails,
                              'acquirer_email',
                              acquirerDataSources,
                              updateAcquirerDataSources,
                              dispatch,
                            );
                          }}
                        />
                        <em>press enter to add new email</em>
                      </div>
                    </div>
                    <div>
                      <TagsInput
                        required
                        type="text"
                        value={item.merchants}
                        onChange={(e: any) =>
                          handleMerchantValueChange(e, i, 'merchants')
                        }
                        name="merchants"
                        placeHolder="enter merchant name"
                      />
                      <em>press enter to add new merchant</em>
                    </div>
                    <MuiAccordion
                      id={`data_source_type-${uid}`}
                      expanded={isSubAccordionExpanded}
                      onChange={() =>
                        dispatch(toggleAccordion(`data_source_type-${uid}`))
                      }
                    >
                      <MuiAccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={<ArrowForwardIosSharpIcon />}
                      >
                        <Typography>Acquirer Data Source</Typography>
                      </MuiAccordionSummary>
                      <MuiAccordionDetails>
                        <div className="add-data-source-div">
                          <p style={{ fontWeight: 'bold' }}>
                            Add Acquirer Data Source
                          </p>
                          <button
                            className="add-data-source-button"
                            onClick={(e) =>
                              handleAddDataSourceClick(e, i, item)
                            }
                            type="button"
                          >
                            <AddCircleSharpIcon />
                          </button>
                        </div>
                        {isSubAccordionExpanded &&
                          item?.data_source_type.map(
                            (sourceType: any, j: any) => {
                              return (
                                <div
                                  className="data-source-type-div"
                                  key={sourceType.uid}
                                >
                                  <div className="data-source-type-inner-div">
                                    <FormControl required fullWidth>
                                      <InputLabel id="demo-simple-select-label">
                                        Data Source Type
                                      </InputLabel>
                                      <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={sourceType.selected_type}
                                        label="Data Source Type"
                                        onChange={(e: SelectChangeEvent) =>
                                          handleInputChange(e, i, item, j)
                                        }
                                      >
                                        <MenuItem value="MFT4U">MFT4U</MenuItem>
                                      </Select>
                                    </FormControl>
                                    {sourceType.selected_type === 'Api' && (
                                      <ApiDataSource
                                        dataType="acquirer_data_sources"
                                        dataTypeIndex={j}
                                        dataSourceIndex={i}
                                      />
                                    )}
                                    {sourceType.selected_type === 'azure' && (
                                      <AzureDataSource
                                        dataType="acquirer_data_sources"
                                        dataTypeIndex={j}
                                        dataSourceIndex={i}
                                      />
                                    )}
                                    {sourceType.selected_type === 'MFT4U' && (
                                      <MftDataSource
                                        dataType="acquirer_data_sources"
                                        dataTypeIndex={j}
                                        dataSourceIndex={i}
                                      />
                                    )}
                                  </div>
                                  <div>
                                    <button
                                      className="remove-data-source-button"
                                      onClick={(e) =>
                                        handleRemoveDataSourceClick(
                                          e,
                                          i,
                                          j,
                                          item,
                                        )
                                      }
                                    >
                                      <RemoveCircleSharpIcon />
                                    </button>
                                  </div>
                                </div>
                              );
                            },
                          )}
                      </MuiAccordionDetails>
                    </MuiAccordion>
                  </div>
                </div>
                <div>
                  {acquirerDataSources.length !== 1 && (
                    <button
                      className="remove-merchant-button"
                      onClick={(e) => handleRemoveClick(e, i, item)}
                    >
                      <RemoveCircleSharpIcon />
                    </button>
                  )}
                </div>
              </div>
            </MuiAccordionDetails>
          </MuiAccordion>
        );
      })}
    </>
  );
};

export default AddAcquirerDataSource;
