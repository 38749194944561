export default {
  local: {
    redirectionUrl: 'http://localhost:3000',
    apiBaseUrl: 'https://api-dev.placesinsights.pwc.com',
    token_info:
      'https://login-stg.pwc.com/openam/oauth2/tokeninfo?access_token=',
    logoutUrl: 'https://login-stg.pwc.com:443/openam/UI/Logout',
    client_id: 'urn:devpici',
    authorization_endpoint: 'https://login-stg.pwc.com/openam/oauth2/authorize',
    token_endpoint: 'https://login-stg.pwc.com/openam/oauth2/access_token',
    user_profile_url: 'https://login-stg.pwc.com/openam/oauth2/userinfo',
    airflowUrl: 'https://airflow-dev.placesinsights.pwc.com',
    databricks: '',
    mft4usnow: '',
    mft4unis: '',
    mft4ex: '',
    mft4in: '',
    mft4u_workspace: 'PwCUK_PICIMFT_LOCAL',
  },
  dev: {
    redirectionUrl: 'https://dev.placesinsights.pwc.com',
    apiBaseUrl: 'https://api-dev.placesinsights.pwc.com',
    token_info:
      'https://login-stg.pwc.com/openam/oauth2/tokeninfo?access_token=',
    logoutUrl: 'https://login-stg.pwc.com:443/openam/UI/Logout',
    client_id: 'urn:devpici',
    authorization_endpoint: 'https://login-stg.pwc.com/openam/oauth2/authorize',
    token_endpoint: 'https://login-stg.pwc.com/openam/oauth2/access_token',
    user_profile_url: 'https://login-stg.pwc.com/openam/oauth2/userinfo',
    airflowUrl: 'https://airflow-dev.placesinsights.pwc.com',
    databricks: 'https://adb-6683586801704524.4.azuredatabricks.net/',
    mft4usnow:
      'https://wwwpwcnetwork.pwc.myshn.net/pwc?id=sc_cat_item&sys_id=7aa4d930db2e1b007408520adc96197c&shn-direct',
    mft4unis: 'https://nifadmin.pwcinternal.com/',
    mft4ex: 'https://mft4u-s.pwc.com',
    mft4in: 'https://mft4u-s.pwcinternal.com',
    mft4u_workspace: 'PwCUK_PICIMFT_STG',
  },
  test: {
    redirectionUrl: 'https://test.placesinsights.pwc.com',
    apiBaseUrl: 'https://api-test.placesinsights.pwc.com',
    token_info:
      'https://login-stg.pwc.com/openam/oauth2/tokeninfo?access_token=',
    logoutUrl: 'https://login-stg.pwc.com:443/openam/UI/Logout',
    client_id: 'urn:devpici',
    authorization_endpoint: 'https://login-stg.pwc.com/openam/oauth2/authorize',
    token_endpoint: 'https://login-stg.pwc.com/openam/oauth2/access_token',
    user_profile_url: 'https://login-stg.pwc.com/openam/oauth2/userinfo',
    airflowUrl: 'https://airflow-test.placesinsights.pwc.com',
    databricks: 'https://adb-5642122615530961.1.azuredatabricks.net/',
    mft4usnow:
      'https://wwwpwcnetwork.pwc.myshn.net/pwc?id=sc_cat_item&sys_id=7aa4d930db2e1b007408520adc96197c&shn-direct',
    mft4unis: 'https://nifadmin.pwcinternal.com/',
    mft4ex: 'https://mft4u.pwc.com',
    mft4in: 'https://mft4u.pwcinternal.com',
    mft4u_workspace: 'PwCUK_PICIMFT_PRD',
  },
  stage: {
    redirectionUrl: 'https://stage.customerinsights.pwc.com',
    apiBaseUrl: 'https://api-stage.customerinsights.pwc.com',
    token_info:
      'https://login-stg.pwc.com/openam/oauth2/tokeninfo?access_token=',
    logoutUrl: 'https://login-stg.pwc.com:443/openam/UI/Logout',
    client_id: 'urn:cistage',
    authorization_endpoint: 'https://login-stg.pwc.com/openam/oauth2/authorize',
    token_endpoint: 'https://login-stg.pwc.com/openam/oauth2/access_token',
    user_profile_url: 'https://login-stg.pwc.com/openam/oauth2/userinfo',
    airflowUrl: 'https://airflow-stage.customerinsights.pwc.com',
    databricks: 'https://adb-6407096044520711.11.azuredatabricks.net/',
    mft4usnow:
      'https://wwwpwcnetwork.pwc.myshn.net/pwc?id=sc_cat_item&sys_id=7aa4d930db2e1b007408520adc96197c&shn-direct',
    mft4unis: 'https://nifadmin.pwcinternal.com/',
    mft4ex: 'https://mft4u-s.pwc.com',
    mft4in: 'https://mft4u-s.pwcinternal.com',
    mft4u_workspace: 'PwCUK_picistage_STG',
  },
  prod: {
    redirectionUrl: 'https://customerinsights.pwc.com',
    apiBaseUrl: 'https://api.customerinsights.pwc.com',
    token_info: 'https://login.pwc.com/openam/oauth2/tokeninfo?access_token=',
    logoutUrl: 'https://login.pwc.com/openam/oauth2/connect/endSession',
    client_id: 'urn:ciprod',
    authorization_endpoint: 'https://login.pwc.com/openam/oauth2/authorize',
    token_endpoint: 'https://login.pwc.com/openam/oauth2/access_token',
    user_profile_url: 'https://login.pwc.com/openam/oauth2/userinfo',
    airflowUrl: 'https://airflow.customerinsights.pwc.com',
    databricks: 'https://adb-8773678389649848.8.azuredatabricks.net',
    mft4usnow:
      'https://wwwpwcnetwork.pwc.myshn.net/pwc?id=sc_cat_item&sys_id=7aa4d930db2e1b007408520adc96197c&shn-direct',
    mft4unis: 'https://nifadmin.pwcinternal.com/',
    mft4ex: 'https://mft4u.pwc.com',
    mft4in: 'https://mft4u.pwcinternal.com',
    mft4u_workspace: 'PwCUK_picistage_PRD',
  },
}[process.env.REACT_APP_ENV];
