// @ts-nocheck
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import ApiDataSource from '../../components/ApiDataSource/ApiDataSource';
import AzureDataSource from '../../components/AzureDataSource/AzureDataSource';
import MftDataSource from '../../components/MftDataSource/MftDataSource';
import { useSelector, useDispatch } from 'react-redux';
import { updateThirdPartyDataSources } from '../../slices/formFieldSlice';

import './AddThirdPartyDataSource.css';

const AddThirdPartyDataSource = (props: any) => {
  const dispatch = useDispatch();
  const thirdPartyDataSources = useSelector(
    (state) => state.formField.third_party_data_sources,
  );
  const engagementDetails = useSelector(
    (state) => state.formField.engagement_details,
  );
  const handleInputChange = (
    e: SelectChangeEvent,
    index: any,
    item: any,
    j: any,
  ) => {
    e.preventDefault();
    const updatedValues = structuredClone(thirdPartyDataSources);
    updatedValues[index].data_source_type[j].selected_type = e.target.value;
    if (e.target.value === 'Api') {
    } else if (e.target.value === 'azure') {
      updatedValues[index].data_source_type[j].url = '';
      updatedValues[index].data_source_type[j].sas_token = '';
      // updatedValues[index].data_source_type[j].validity = "";
      updatedValues[index].data_source_type[j].file = [];
      updatedValues[index].data_source_type[j].file_count =
        updatedValues[index].data_source_type[j].file.length;
    } else if (e.target.value === 'MFT4U') {
      updatedValues[index].data_source_type[j].email = [];
      updatedValues[index].data_source_type[j].file = [];
      updatedValues[index].data_source_type[j].file_count =
        updatedValues[index].data_source_type[j].file.length;
    }

    dispatch(updateThirdPartyDataSources({ data: updatedValues }));
  };

  const handleAddDataSourceClick = (e: any, i: any, item: any) => {
    e.preventDefault();
    const updatedValues = structuredClone(thirdPartyDataSources);
    updatedValues[i].data_source_type = [
      ...updatedValues[i].data_source_type,
      { uid: crypto.randomUUID(), selected_type: '' },
    ];
    dispatch(updateThirdPartyDataSources({ data: updatedValues }));
  };

  const handleRemoveDataSourceClick = (e: any, i: any, j: any, item: any) => {
    e.preventDefault();

    const updatedValues = structuredClone(thirdPartyDataSources);
    updatedValues[i].data_source_type.splice(j, 1);
    dispatch(updateThirdPartyDataSources({ data: updatedValues }));
  };

  return (
    <>
      {!engagementDetails.data_providers.includes('third_party') ? (
        <p>
          Please select "Third Party" as a data provider type in Step 1 if you
          would like to add an third party data source. Otherwise, please click
          the NEXT button to continue.
        </p>
      ) : (
        <>
          {thirdPartyDataSources?.map((item: any, i: any) => {
            return (
              <div key={item.uid} className="data-source-div">
                <div className="add-data-source-div">
                  <p style={{ fontWeight: 'bold' }}>
                    Add Third Party Data Source
                  </p>
                  <button
                    className="add-data-source-button"
                    onClick={(e) => handleAddDataSourceClick(e, i, item)}
                    type="button"
                  >
                    <AddCircleSharpIcon />
                  </button>
                </div>
                {item?.data_source_type?.map((sourceType: any, j: any) => {
                  return (
                    <div key={sourceType.uid} className="data-source-type-div">
                      <div className="data-source-type-inner-div">
                        <FormControl required fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Data Source Type
                          </InputLabel>
                          <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sourceType.selected_type}
                            label="Data Source Type"
                            onChange={(e: SelectChangeEvent) =>
                              handleInputChange(e, i, item, j)
                            }
                          >
                            <MenuItem value="weather_data_rainfall">
                              Weather Data (Rainfall)
                            </MenuItem>
                            <MenuItem value="weather_data_temperature">
                              Weather Data (Temperature)
                            </MenuItem>
                            <MenuItem value="foreign_exchange_rates">
                              Foreign Exchange Rates
                            </MenuItem>
                            <MenuItem value="azure">Microsoft Azure</MenuItem>
                            <MenuItem value="MFT4U">MFT4U</MenuItem>
                          </Select>
                        </FormControl>
                        {sourceType.selected_type === 'Api' && (
                          <ApiDataSource
                            dataType="third_party_data_sources"
                            dataTypeIndex={j}
                            dataSourceIndex={i}
                          />
                        )}
                        {sourceType.selected_type === 'azure' && (
                          <AzureDataSource
                            dataType="third_party_data_sources"
                            dataTypeIndex={j}
                            dataSourceIndex={i}
                          />
                        )}
                        {sourceType.selected_type === 'MFT4U' && (
                          <MftDataSource
                            dataType="third_party_data_sources"
                            dataTypeIndex={j}
                            dataSourceIndex={i}
                          />
                        )}
                      </div>
                      <div>
                        <button
                          className="remove-data-source-button"
                          onClick={(e) =>
                            handleRemoveDataSourceClick(e, i, j, item)
                          }
                        >
                          <RemoveCircleSharpIcon />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default AddThirdPartyDataSource;
