// @ts-nocheck
import { useState } from 'react';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import AddAcquirerDataSource from '../../components/AddAcquirerDataSource/AddAcquirerDataSource';
import { useSelector } from 'react-redux';
import './CreateAcquirerSource.css';

const CreateAcquirerSource = () => {
  const [inputList, setInputList] = useState([
    { firstName: '', lastName: '', uid: crypto.randomUUID() },
  ]);
  const engagementDetailsDataProviders = useSelector(
    (state) => state.formField.engagement_details.data_providers,
  );
  // handle click event of the Remove button
  const handleRemoveClick = (e: any, index: any) => {
    e.preventDefault();
    const list = [...structuredClone(inputList)];
    list.splice(index, 1);
    setInputList(list);
  };

  return (
    <>
      {!engagementDetailsDataProviders.includes('acquirer') ? (
        <p>
          Please select "Acquirer" as a data provider type in Step 1 if you
          would like to add an acquirer data source. Otherwise, please click the
          NEXT button to continue.'
        </p>
      ) : (
        <>
          {inputList?.map((input, i) => {
            return (
              <div key={input.uid}>
                <div className="main-div">
                  <div className="btn-box remove-button-div">
                    {inputList.length !== 1 && (
                      <button
                        className="remove-button"
                        onClick={(e) => handleRemoveClick(e, i)}
                      >
                        <RemoveCircleSharpIcon />
                      </button>
                    )}
                  </div>
                </div>
                <AddAcquirerDataSource />
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default CreateAcquirerSource;
